export class Util {




  public static arraySetEquals(lhs: string[], rhs: string[] ): boolean {
    if (lhs.length !== rhs.length){
      return false;
    }
    const stringSort = (a: string, b: string) => a.localeCompare(b);
    lhs = [].concat(lhs).sort(stringSort);
    rhs = [].concat(rhs).sort(stringSort);

    return Util.arrayEquals(lhs, rhs);
  }
  public static arrayEquals(lhs: string[], rhs: string[] ): boolean{
    if (lhs == null && rhs == null){
      return true;
    }

    if (lhs.length !== rhs.length) {
      return false;
    }

    for (let i = 0; i < rhs.length; i++) {
      if (!(rhs[i] === lhs[i])) {
        return false;
      }
    }
    return true;
  }

}

<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">

        <a *ngIf="!showDropDown" routerlink="/regionSector" href="/regionSector" mat-button style='color: rgba(255, 255, 255, 0.87)'>

          <span><i class="material-icons" matTooltip="Sectors of the region statistic">arrow_back</i> </span>
        </a>

        <span *ngIf="region === ''">Sectors of the region statistic</span>
        <span *ngIf="region !== ''"> Sectors of the region statistic - {{region}}</span>

      </h1>
    </div>
  </div>
</header>

<mat-card *ngIf="showDropDown">
<mat-form-field class="input-width">
  <mat-select [(ngModel)]="selectedCategory"
              placeholder="Choose a NACE 2 codes economic activity"
              id="activity">
    <mat-option *ngFor="let a of activities" [value]="a">
      {{a}}
    </mat-option>
  </mat-select>
</mat-form-field>
<button mat-mini-fab color="primary" matTooltip="start compare" (click)="showDiagramForActivity()" style="margin:5px;"><mat-icon aria-label="filter">done</mat-icon></button>
</mat-card>

<mat-card>

    <div [chart]="chart"></div>

</mat-card>

<mat-card *ngIf="showAllTable">
  <table class="table">
    <tr>
      <th>Region</th>
      <th>2008</th>
      <th>2012</th>
      <th>2017</th>
    </tr>
    <tr *ngFor="let element of regionSectorData">
      <td>{{element.region}}</td>
      <td>{{element.data[position].year2008}}</td>
      <td>{{element.data[position].year2012}}</td>
      <td>{{element.data[position].year2017}}</td>
    </tr>
  </table>
</mat-card>

<mat-card *ngIf="showPartnerTable">
  <table class="table">
    <tr>
      <th>2008</th>
      <th>2012</th>
      <th>2017</th>
    </tr>
    <tr *ngFor="let element of regionSectorElements">
      <td>{{element.year2008}}</td>
      <td>{{element.year2012}}</td>
      <td>{{element.year2017}}</td>
    </tr>
  </table>
</mat-card>


<mat-card *ngIf="showIndicatorTable" style="margin-bottom: 100px">
  <table class="table">
    <tr>
      <th>2008</th>
      <th>2012</th>
      <th>2017</th>
    </tr>
    <tr>
      <td>{{regionSectorElement.year2008}}</td>
      <td>{{regionSectorElement.year2012}}</td>
      <td>{{regionSectorElement.year2017}}</td>
    </tr>
  </table>
</mat-card>


import {Component, OnInit} from "@angular/core";
import {Chart} from "angular-highcharts";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {DataService} from "../../data-service";
import {Popup_infoComponent} from "../popup_info.component";
import {RegionSectorElement} from "./region_sector";

@Component({
  templateUrl: './region_sector.component.html',
  styleUrls: ['../framework.css'],
})
export class RegionSectorComponent implements OnInit {

  regionSectorData = []
  selectedCategory = 'mining and quarrying';
  activities = ['mining and quarrying','manufacturing','electricity, gas, steam and air conditioning supply',
  'water supply; sewerage, waste management and remediation activities','construction', 'wholesale and retail trade; repair of motor vehicles and motorcycles',
  'transportation and storage','accommodation and food service activities', 'information and communication', 'real estate activities',
  'professional, scientific and technical activities','administrative and support service activities'];
  showDropDown = true;
  region = '';
  showAllTable = true;
  showPartnerTable = false;
  showIndicatorTable = false;
  position = 0;

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.dataService.getStatisticDataRegionSector().subscribe(res=>{
      this.regionSectorData = res;
      for(let e of this.regionSectorData){
        let tmp = e.region;
        this.chart.addSeries({type: 'column', name: e.region, data: [{name: '2008', y: e.data[0].year2008},
          {name: '2012', y: e.data[0].year2012}, {name: '2017' , y: e.data[0].year2017}], events: {
          click: (e) => {
            this.region = tmp;
            this.drawDiagramForPartner(tmp)
          }
        }}, true, false);
      }
    })



  }

  showDiagramForActivity(){
    this.initializeChart()
    this.showAllTable = true;
    this.showPartnerTable = false;
    this.showIndicatorTable = false;

    let pos = this.getNumberOfActivity(this.selectedCategory);
    this.position = pos;
    for(let e of this.regionSectorData){
      let tmp = e.region;
      this.chart.addSeries({type: 'column', name: e.region, data: [{name: '2008', y: e.data[pos].year2008},
        {name: '2012', y: e.data[pos].year2012}, {name: '2017' , y: e.data[pos].year2017}], events: {
        click: (e) => {
          this.region = tmp;
          this.drawDiagramForPartner(tmp)
        }
      }}, true, false);
    }
  }

  regionSectorElements:RegionSectorElement[] = [];
  drawDiagramForPartner(region){
    this.initializeChart();
    this.showDropDown = false;
    this.showAllTable = false;
    this.showPartnerTable = true;
    this.showIndicatorTable = false;


    //this.chart.options.title.text = 'Statistic data of the region\'s sectors '
    for (let c of this.regionSectorData) {
      if(c.region === region){
        for(let i = 0; i <= 10; i++) {
          this.regionSectorElements[i] = new RegionSectorElement(c.data[i].year2008,c.data[i].year2012,c.data[i].year2017);
          this.chart.addSeries({
            type: 'column',
            name: this.getNameOfActivity(i),
            data: [{name: '2008', y: c.data[i].year2008}, {name: '2012', y: c.data[i].year2012}, {name: '2017', y: c.data[i].year2017}],
            events: {
              click: (e) => {
               this.drawDiagramForIndicator(this.getNameOfActivity(i),i, region)
              }
            }
          }, true, false)
        }
      }
    }
  }

  regionSectorElement = new RegionSectorElement(0,0,0);
  drawDiagramForIndicator(indicator, index, region){
    this.showAllTable = false;
    this.showPartnerTable = false;
    this.showIndicatorTable = true;
    this.initializeChartLine('Statistic data of the region\'s sectors '  + indicator);
    for (let c of this.regionSectorData) {
      if (c.region === region) {
        this.regionSectorElement.year2017 = c.data[index].year2017;
        this.regionSectorElement.year2012 = c.data[index].year2012;
        this.regionSectorElement.year2008 = c.data[index].year2008;

        this.chart.addSeries({
          type: 'column',
          name: indicator,
          data: [{name: '2008', y: c.data[index].year2008}, {name: '2012', y: c.data[index].year2012}, {name: '2017', y: c.data[index].year2017}],
        }, true, false)
      }
    }
  }


  getNameOfActivity(activity): string{
    switch (activity) {
      case 0:
        return 'mining and quarrying';
      case 1:
        return 'manufacturing';
      case 2:
        return 'electricity, gas, steam and air conditioning supply';
      case 3:
        return 'water supply; sewerage, waste management and remediation activities';
      case 4:
        return 'construction';
      case 5:
        return  'wholesale and retail trade; repair of motor vehicles and motorcycles';
      case 6:
        return 'transportation and storage';
      case 7:
        return 'accommodation and food service activities';
      case 8:
        return 'information and communication';
      case 9:
        return 'real estate activities';
      case 10:
        return 'professional, scientific and technical activities';
      case 11:
        return  'administrative and support service activities';
      default:
        return '';
    }
  }

  getNumberOfActivity(activity): number{
    switch (activity) {
      case 'mining and quarrying':
        return 0;
      case 'manufacturing':
        return 1;
      case 'electricity, gas, steam and air conditioning supply':
        return 2;
      case 'water supply; sewerage, waste management and remediation activities':
        return 3;
      case 'construction':
        return 4;
      case 'wholesale and retail trade; repair of motor vehicles and motorcycles':
        return 5;
      case 'transportation and storage':
        return 6;
      case 'accommodation and food service activities':
        return 7;
      case 'information and communication':
        return 8;
      case 'real estate activities':
        return 9;
      case 'professional, scientific and technical activities':
        return 10;
      case 'administrative and support service activities':
        return 11;
      default:
        return 0;
    }
  }

  initializeChart(){
    this.chart = new Chart( {chart: {
      type: 'column',
    },title: {
      text: 'Statistic data of the region\'s sectors'
    },
      xAxis: {
        title: {
          text: ''
        },
        labels:
          {
            enabled: true
          },
        categories: ['2008', '2012', '2017']
      },
      yAxis: {
        title: {
          text: 'Percentage'
        }
      },
      credits: {
        enabled: true
      }
    });

  }

  initializeChartLine(titleText){
    this.chart = new Chart( {chart: {
      type: 'line',
    },title: {
      text: titleText
    },
      xAxis: {
        title: {
          text: ''
        },
        labels:
          {
            enabled: true
          },
        categories: ['2008', '2012', '2017']
      },
      yAxis: {
        title: {
          text: 'Percentage'
        }
      },
      credits: {
        enabled: true
      }
    });

  }

  chart = new Chart( {chart: {
    type: 'column',
  },title: {
    text: 'Statistic data of the region\'s sectors'
  },
    xAxis: {
      title: {
        text: ''
      },
      labels:
        {
          enabled: true
        },
      categories: ['2008', '2012', '2017']
    },
    yAxis: {
      title: {
        text: 'Percentage'
      }
    },
    credits: {
      enabled: true
    }
  });


}


    <h2 mat-dialog-title><i class="material-icons icon">cloud_off</i> Connectivity problems</h2>
    <mat-dialog-content>
      <div>
        <p>
        The application lost the connection to the backend server.
        </p>
        <p>
          Please wait while the application re-establishes the connection.
        </p>
      </div>
      <div>
        <mat-progress-spinner class="centered" color="warn" mode="indeterminate">

        </mat-progress-spinner>
      </div>
    </mat-dialog-content>
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../material/material.module";
import {HttpModule} from "@angular/http";
import {HttpClientModule} from "@angular/common/http";
import {
  MatCardModule, MatDialogModule, MatExpansionModule, MatSidenavModule, MatSortModule, MatTableModule,
  MatTooltipModule
} from "@angular/material";
import {RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import { SwOverlayComponent} from "./swOverlay.component";
import {ChartModule} from "angular-highcharts";
import {PopupInfoComponent} from "./popup_info.component";
import {PopupDataComponent} from "./popup_data.component";
import {CommonModule} from "@angular/common";
import {MultipleChoiceCheckboxComponent} from "../common/multiple-choice-checkbox.component";
import {CommonComponentsModule} from "../common/common.module";


@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpModule,
    HttpClientModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    RouterModule,
    MatCardModule,
    ChartModule,
    MatDialogModule,
    FormsModule,
    MatSidenavModule,
    MatExpansionModule,
    CommonComponentsModule
  ],
  exports: [
    SwOverlayComponent
  ],
  declarations: [
    SwOverlayComponent,
    PopupInfoComponent,
    PopupDataComponent
  ],providers: [
  ],
  entryComponents: [
    PopupInfoComponent,
    PopupDataComponent
  ]
})
export class SwOverlayModule {}


<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">

        <a *ngIf="region !== ''" routerlink="/regionalEconomy" href="/regionalEconomy" mat-button style='color: rgba(255, 255, 255, 0.87)'>

          <span><i class="material-icons" matTooltip="Regional Economy statistic">arrow_back</i> </span>
        </a>
        <span *ngIf="region !== ''">Regional Economy Statistic - {{region}}</span>
        <span *ngIf="region === ''">Regional Economy Statistic</span>
      </h1>
    </div>
  </div>
</header>

<mat-card *ngIf="showDropDown">
  <mat-form-field class="input-width">
    <mat-select [(ngModel)]="selectedIndicator"
                placeholder="Choose an indicator"
                id="indicators">
      <mat-option *ngFor="let i of indicators" [value]="i">
        {{i}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-mini-fab color="primary" matTooltip="start compare" (click)="showDiagramForEconomyStatistic()" style="margin:5px;"><mat-icon aria-label="filter">done</mat-icon></button>
</mat-card>

<mat-card>

  <div [chart]="chart"></div>

</mat-card>


<mat-card *ngIf="showAllTable">
  <table class="table">
    <tr>
      <th>Region</th>
      <th>2005</th>
      <th>2008</th>
      <th>2011</th>
      <th>2014</th>
      <th>2017</th>
    </tr>
    <tr *ngFor="let element of regionalEconomyData">
      <td>{{element.region}}</td>
      <td>{{element.data[position].year2005}}</td>
      <td>{{element.data[position].year2008}}</td>
      <td>{{element.data[position].year2011}}</td>
      <td>{{element.data[position].year2014}}</td>
      <td>{{element.data[position].year2017}}</td>
    </tr>
  </table>
</mat-card>


<mat-card *ngIf="showDetailTable" style="margin-bottom: 100px">
  <table class="table">
    <tr>
      <th>2005</th>
      <th>2008</th>
      <th>2011</th>
      <th>2014</th>
      <th>2017</th>
    </tr>
    <tr>
      <td>{{regionalEconomy.year2005}}</td>
      <td>{{regionalEconomy.year2008}}</td>
      <td>{{regionalEconomy.year2011}}</td>
      <td>{{regionalEconomy.year2014}}</td>
      <td>{{regionalEconomy.year2017}}</td>
    </tr>
  </table>
</mat-card>

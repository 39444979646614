export class SimilarityDemandResponse {
    region : string;
    country : string;
    similarityROs : SimilarityResult[];
    averageRes : number;
    averageResNeg: number;
}

export class SimilarityResult {
  obsName: string;
  id: string;
  region: string;
  results: number[];
  resultsNegativ: number[];
}

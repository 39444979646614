export class Competitiveness{
  country : string = '';
  region : string = '';
  shortname : string = '';
  data : CompetitivenessData[] = [];
}

export class CompetitivenessData{
  year2009 : number = 0;
  year2012 : number = 0;
  year2014 : number = 0;
}

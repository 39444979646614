<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">

        <a *ngIf="!showDropDown" routerlink="/innovation" href="/innovation" mat-button style='color: rgba(255, 255, 255, 0.87)'>

          <span><i class="material-icons" matTooltip="Innovation statistic">arrow_back</i> </span>
        </a>

      <span *ngIf="region === ''">Innovation Statistic</span>
      <span *ngIf="region !== ''">Innovation Statistic - {{region}}</span>
      </h1>

    </div>
  </div>
</header>

<mat-card *ngIf="showDropDown">
  <mat-form-field class="input-width">
    <mat-select [(ngModel)]="selectedIndicator"
                placeholder="Choose an indicator"
                id="indicators">
      <mat-option *ngFor="let i of indicators" [value]="i">
        {{i}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-mini-fab color="primary" matTooltip="start compare" (click)="showDiagramForInnovation()" style="margin:5px;"><mat-icon aria-label="filter">done</mat-icon></button>
</mat-card>

<mat-card>

  <div [chart]="chart"></div>

</mat-card>


<mat-card *ngIf="showAllTable">
  <table class="table">
    <tr>
      <th>Region</th>
      <th>2009</th>
      <th>2012</th>
      <th>2014</th>
      <th>2017</th>
    </tr>
    <tr *ngFor="let element of innovationData">
      <td>{{element.region}}</td>
      <td>{{element.data[position].year2009}}</td>
      <td>{{element.data[position].year2012}}</td>
      <td>{{element.data[position].year2014}}</td>
      <td>{{element.data[position].year2017}}</td>
    </tr>
  </table>
</mat-card>


<mat-card *ngIf="showPartnerTable">
  <table class="table">
    <tr>
      <th>2009</th>
      <th>2012</th>
      <th>2014</th>
      <th>2017</th>
    </tr>
    <tr *ngFor="let element of innovationDatas">
      <td>{{element.year2009}}</td>
      <td>{{element.year2012}}</td>
      <td>{{element.year2014}}</td>
      <td>{{element.year2017}}</td>
    </tr>
  </table>
</mat-card>


<mat-card *ngIf="showIndicatorTable" style="margin-bottom: 100px">
  <table class="table">
    <tr>
      <th>2009</th>
      <th>2012</th>
      <th>2014</th>
      <th>2017</th>
    </tr>
    <tr>
      <td>{{innovationDataElement.year2009}}</td>
      <td>{{innovationDataElement.year2012}}</td>
      <td>{{innovationDataElement.year2014}}</td>
      <td>{{innovationDataElement.year2017}}</td>
    </tr>
  </table>
</mat-card>


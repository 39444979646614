
export class RegionSector {
  country : string = '';
  region : string = '';
  shortname : string = '';
  data : RegionSectorElement[] = [];
}


export class RegionSectorElement {
  year2008 : number = 0;
  year2012 : number = 0;
  year2017 : number = 0;

  constructor(year2008,year2012,year2017){
    this.year2017 = year2017;
    this.year2012 = year2012;
    this.year2008 = year2008;
  }
}

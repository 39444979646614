<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">
        Service: Supply and Demand </h1>
    </div>
  </div>
</header>
<span *ngIf="breadcrumVisible1" style="color:#3f51b5; margin-left: 1%" [innerHTML]="breadcrumb1" (click)="setData()"></span>
<span *ngIf="regionNotClick">{{region}}</span>
<span *ngIf="breadcrumVisible2" style="color:#3f51b5;" [innerHTML]="breadcrumb2" (click)="selectRegion(region)"></span>
<span *ngIf="regionAllNotClick">{{regionAll}}</span>
<span *ngIf="obsNotClick">{{obsBreadcrumb}}</span>
<span *ngIf="breadcrumb3Visible" style="color:#3f51b5;" [innerHTML]="breadcrumb3" (click)="selectObservatory(curObservatory)"></span>


<mat-card *ngIf="showAllTable || showAllObsTable">
  <mat-card-content>
    <span>Show per observatory or region</span>
  </mat-card-content>
  <mat-form-field class="input-width">
    <mat-select [(ngModel)]="selected"
                id="ro"
                (selectionChange)="chooseDiagram()"
    >
      <mat-option *ngFor="let e of selection" [value]="e" >
        {{e}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-card>

<mat-card *ngIf="showSelection">
  <mat-card-content>
    <span>Compare with all regions or own region</span>
  </mat-card-content>
  <mat-form-field class="input-width">
    <mat-select [(ngModel)]="selectedObs"
                id="obs"
                (selectionChange)="chooseDiagramObs()"
    >
      <mat-option *ngFor="let e of selectionObs" [value]="e" >
        {{e}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-card>

<mat-card *ngIf="showSelectionRegion">
  <mat-card-content>
    <span>Compare with all observatories of the region or all observatories</span>
  </mat-card-content>
  <mat-form-field class="input-width">
    <mat-select [(ngModel)]="selectedRegion"
                id="reg"
                (selectionChange)="chooseDiagramRegion()"
    >
      <mat-option *ngFor="let e of selectionRegion" [value]="e" >
        {{e}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-slide-toggle [checked]="selectedToggle" (change)="checkButtonToggle()">
    get average per category for the selected region
  </mat-slide-toggle>
</mat-card>

<mat-card>
<mat-card-header>{{textSelection}} <!--  - <a href="javascript:void(0)" (click)="openDialogInfo()"> Detail information about Pearson and the used fields</a>--></mat-card-header>
  <mat-card-content>
    <!--  <button  mat-mini-fab color="primary" matTooltip="Info about Pearson and used fields..." (click)="openDialogInfo()" style="margin:5px; float: right;">
        <mat-icon aria-label="info">info</mat-icon>
      </button>-->
    <div style="width: 100%;" [chart]="chart"></div>
  </mat-card-content>
 <!-- <p style="font-size: small; margin-left: 1%"> * Pearson: 1 => high correlation of demand and supply, 0 => no correlation, -1 => high negativ correlation of demand and supply</p>-->
</mat-card>

<mat-card *ngIf="showAllTable">
  <table class="table">
    <tr>
      <th>Region</th>
      <th>County</th>
      <th>Average Positive</th>
      <th>Average Negative</th>
    </tr>
    <tr *ngFor="let element of similarityRes">
      <td>{{element.region}}</td>
      <td>{{element.country}}</td>
      <td>{{element.averageRes.toFixed(3)}}</td>
      <td>{{element.averageResNeg.toFixed(3)}}</td>
    </tr>
  </table>
</mat-card>

<mat-card *ngIf="showAllObsTable">
  <table class="table">
    <tr>
      <td>Observatory</td>
      <th>Average Positive</th>
      <th>Average Negative</th>
    </tr>
    <tr *ngFor="let element of tmpList">
      <td>{{element.obsName}}</td>
      <td>{{element.results[6].toFixed(3)}}</td>
      <td>{{element.resultsNegativ[6].toFixed(3)}}</td>
    </tr>
  </table>
</mat-card>


<mat-card *ngIf="showRegionTable">
  <table class="table">
    <tr>
      <th>Observatory</th>
      <th>Average Positive</th>
      <th>Average Negative</th>
    </tr>
    <tr *ngFor="let element of similarityRes[posRegion].similarityROs">
      <td>{{element.obsName}}</td>
      <td>{{element.results[6].toFixed(3)}}</td>
      <td>{{element.resultsNegativ[6].toFixed(3)}}</td>
    </tr>
  </table>
</mat-card>

<mat-card *ngIf="showAllRegionTable">
  <table class="table">
    <tr>
      <th>Observatory</th>
      <th>Average Positive</th>
      <th>Average Negative</th>
    </tr>
    <tr *ngFor="let element of selectedRegionsData.similarityROs">
      <td>{{element.obsName}}</td>
      <td>{{element.results[6].toFixed(3)}}</td>
      <td>{{element.resultsNegativ[6].toFixed(3)}}</td>
    </tr>
  </table>
</mat-card>

<mat-card *ngIf="showAllRegionObsTable">
  <table class="table">
    <tr>
      <td>Region</td>
      <th>Average Positive</th>
      <th>Average Negative</th>
    </tr>
    <tr *ngFor="let element of tmpListRegion">
      <td>{{element.region}}</td>
      <td>{{element.resultsPos[6].toFixed(3)}}</td>
      <td>{{element.resultsNeg[6].toFixed(3)}}</td>
    </tr>
  </table>
</mat-card>

<!---
    this.chart.addSerie({name: "RO values", data: [+observatory.results[0].toFixed(3)]});
    this.chart.addSerie({name: "RO datasets", data: [+observatory.results[1].toFixed(3)]});
    this.chart.addSerie({name: "RO services", data: [+observatory.results[2].toFixed(3)]});
    this.chart.addSerie({name: "RO channels", data: [+observatory.results[3].toFixed(3)]});
    this.chart.addSerie({name: "Pay services", data: [+observatory.results[4].toFixed(3)]});
    this.chart.addSerie({name: "Cooperations", data: [+observatory.results[5].toFixed(3)]});
-->

<mat-card *ngIf="showObsTable">
  <table class="table">
    <tr>
      <th>Average</th>
      <th>RO values</th>
      <th>RO datasets</th>
      <th>RO services</th>
      <th>RO channels</th>
      <th>Pay services</th>
      <th>Cooperations</th>
    </tr>
    <tr>
      <td>{{curObservatory.results[6].toFixed(3)}}</td>
      <td>{{curObservatory.results[0].toFixed(3)}}</td>
      <td>{{curObservatory.results[1].toFixed(3)}}</td>
      <td>{{curObservatory.results[2].toFixed(3)}}</td>
      <td>{{curObservatory.results[3].toFixed(3)}}</td>
      <td>{{curObservatory.results[4].toFixed(3)}}</td>
      <td>{{curObservatory.results[5].toFixed(3)}}</td>
    </tr>
  </table>
</mat-card>

<mat-card *ngIf="showObsRegionTable">
  <table class="table">
    <tr>
      <th>Average</th>
      <th>RO values</th>
      <th>RO datasets</th>
      <th>RO channels</th>
      <th>Pay services</th>
      <th>Cooperations</th>
    </tr>
    <tr>
      <td>{{curRegionObs.resultsPos[6].toFixed(3)}}</td>
      <td>{{curRegionObs.resultsPos[0].toFixed(3)}}</td>
      <td>{{curRegionObs.resultsPos[1].toFixed(3)}}</td>
      <td>{{curRegionObs.resultsPos[2].toFixed(3)}}</td>
      <td>{{curRegionObs.resultsPos[3].toFixed(3)}}</td>
      <td>{{curRegionObs.resultsPos[4].toFixed(3)}}</td>
    </tr>
  </table>
</mat-card>

<p style="margin-bottom: 50px; margin-left: 1%">For the calculation of the of the supply and demand correlation, different data which were gathered before were used.
  It need to be differed between the positive and negative values. The positive values are calculated by adding one point to a sum if both supply and demand want to fulfill one issue.
  The negative values are calculated by adding one point if there is no demand but an RO offers the issue. Then the average of these values is calculated.
  The demand is represented by data which are entered in the D.T2.1.1 report. The ROs entered their offers in the audit tool. For the calculation following fields of the audit tool were used:
<li>In detail the fields RO values include the areas of smart specialisation and the observatory type</li>
<li>The field RO dataset includes the main RO target and the customer segments of the RO</li>
 <li> The field RO services includes current and future values for customers, the gathered data of the RO and services which are offered by the RO</li>
<li>The field RO channels includes RO premises, if it is necessary for customer to visit the premises, location of premises and the channels which are used for accessing the customer service group</li>
<li>The field Pay services includes educational background of the staff within the RO and the general yaerly budget of the RO</li>
<li>The field cooperation includes long term partnerships, operational partnerships, RO characteristics and number of customers and general users</li>
</p>

import {Component, OnInit} from "@angular/core";
import {Chart} from "angular-highcharts";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material";
import {DataService} from "../../data-service";
import {CompetitivenessData} from "./competitiveness";

@Component({
  templateUrl: './competitiveness.component.html',
  styleUrls: ['../framework.css'],
})
export class CompetitivenessComponent implements OnInit {

  selectedIndicator = 'Competitiveness Index rank';
  indicators = ['Competitiveness Index rank', 'Market size', 'Technological readiness (Households)', 'Technological Readiness (Enterprises)'];
  competitivenessData;
  showDropDown = true;
  region = '';
  showAllTable = true;
  showIndicatorTable = false;
  position = 0;

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.dataService.getStatisticDataCompetitiveness().subscribe(res => {
      this.competitivenessData = res;
      for (let e of this.competitivenessData) {
        let tmp = e.region;
        this.chart.addSeries({
          type: 'column',
          name: e.region, data: [{name: '2010', y: e.data[0].year2009},
            {name: '2013', y: e.data[0].year2012}, {name: '2017', y: e.data[0].year2014}], events: {
            click: (e) => {
              this.region = tmp;
              this.drawDiagramForIndicator()
            }
          }
        }, true, false);
      }
    })
  }

  showDiagramForCompetitiveness() {
    this.showAllTable = true;
    this.showIndicatorTable = false;
    if(this.region === '') {
      this.initializeChart()

      let pos = this.getNumberOfComp(this.selectedIndicator);
      this.position = pos;
      for (let e of this.competitivenessData) {
        let tmp = e.region;

        this.chart.addSeries({
          type: 'column',
          name: e.region, data: [{name: '2010', y: e.data[pos].year2009},
            {name: '2013', y: e.data[pos].year2012}, {name: '2017', y: e.data[pos].year2014}], events: {
            click: (e) => {
              this.region = tmp;
              this.drawDiagramForIndicator()
            }
          }
        }, true, false);
      }
    }else{
      this.drawDiagramForIndicator();
    }
  }

  getNumberOfComp(indicator): number {
    switch (indicator) {
      case 'Competitiveness Index rank':
        return 0;
      case 'Market size':
        return 1;
      case 'Technological readiness (Households)':
        return 2;
      case 'Technological Readiness (Enterprises)':
        return 3;
      default:
        return 0;
    }
  }

  getNumberOfCompet(pos): string {
    switch (pos) {
      case 0:
        return 'Competitiveness Index rank';
      case 1:
        return 'Market size';
      case 2:
        return 'Technological readiness (Households)';
      case 3:
        return 'Technological Readiness (Enterprises)';
      default:
        return '';
    }
  }


 comp = new CompetitivenessData();
  drawDiagramForIndicator(){
    this.initializeChartLine('Competitiveness statistic data ' + this.selectedIndicator)
    this.showAllTable = false;
    this.showIndicatorTable = true;

    let index = this.getNumberOfComp(this.selectedIndicator);
    for (let c of this.competitivenessData) {
      if (c.region === this.region) {
        this.comp.year2009 = c.data[index].year2009
        this.comp.year2012 = c.data[index].year2012
        this.comp.year2014 = c.data[index].year2014
        this.chart.addSeries({
          type: 'column',
          name: this.selectedIndicator,
          data: [{name: '2010', y: c.data[index].year2009}, {name: '2013', y: c.data[index].year2012}, {
            name: '2017',
            y: c.data[index].year2014
          }]
        }, true, false)
      }
    }
  }

  initializeChart() {
    this.chart = new Chart({
      chart: {
        type: 'column'
      }, title: {
        text: 'Competitiveness statistic data'
      },
      xAxis: {
        title: {
          text: ''
        },
        labels:
          {
            enabled: true
          },
        categories: ['2010', '2013', '2017']
      },
      yAxis: {
        title: {
          text: 'Values'
        }
      },
      credits: {
        enabled: true
      }
    });

  }

  initializeChartLine(titleText) {
    this.chart = new Chart({
      chart: {
        type: 'line'
      }, title: {
        text: titleText
      },
      xAxis: {
        title: {
          text: ''
        },
        labels:
          {
            enabled: true
          },
        categories: ['2010', '2013', '2017']
      },
      yAxis: {
        title: {
          text: 'Values'
        }
      },
      credits: {
        enabled: true
      }
    });

  }


  chart = new Chart({
    chart: {
      type: 'column'
    }, title: {
      text: 'Competitiveness statistic data'
    },
    xAxis: {
      title: {
        text: ''
      },
      labels:
        {
          enabled: true
        },
      categories: ['2010', '2013', '2017']
    },
    yAxis: {
      title: {
        text: 'Values'
      }
    },
    credits: {
      enabled: true
    }
  });

}

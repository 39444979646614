
import {Component, HostListener, OnInit} from "@angular/core";
import {DataService} from "../data-service";
import {ActivatedRoute, Router} from "@angular/router";
import {Benchlearning} from "./benchmarking";
import * as FileSaver from "file-saver";
import {AuthService} from "../auth/auth.service";
import {Filter} from "./filter";
import {CheckboxOption} from "../common/multiple-choice-checkbox.component";
import {element} from "protractor";
import {PersistenceService, StorageType} from "angular-persistence";
import {isUndefined} from "util";




@Component({
  templateUrl: './benchmarking.component.html',
  styleUrls: ['./benchmarking.component.css'],
})
export class BenchmarkingComponent implements OnInit {
  benchlearningList: Benchlearning[] = [];
  sortedList: Benchlearning[] = [];
  filter = Filter.empty();
  filterVisible = false;
  showFilterVisible = true;
  enableBreak = false;
  amountRes = 0;
  showFilterSt;

  ngOnInit(): void {
    //stores and check the last state of the filter (open or closed)
    if(!isUndefined(this.persistenceService.get('filterVisibleST',StorageType.LOCAL))) {
      this.showFilterSt = this.persistenceService.get('filterVisibleST',StorageType.LOCAL);
    }else {
      this.persistenceService.set('filterVisibleST', true, {type: StorageType.LOCAL});
      this.showFilterSt = true;
    }

    if(window.innerWidth < 1404){
      this.enableBreak = true;
    }
      this.dataService.getBenchlearning().subscribe(
        r => {
          this.benchlearningList = r;
          this.sortedList = this.benchlearningList;
          this.amountRes = this.benchlearningList.length;
        }
      )
  }

  openCloseFilter(){
    if(this.showFilterSt){
      this.persistenceService.set('filterVisibleST', false, {type: StorageType.LOCAL});
    }else {
      this.persistenceService.set('filterVisibleST', true, {type: StorageType.LOCAL});
    }
  }

  getPolicyTechnology(){
    if(this.filter.obsPolicy && this.filter.obsTechnology){
      return 'technology and policy';
    }else if(this.filter.obsPolicy){
      return 'policy';
    }else if(this.filter.obsTechnology){
      return 'technology'
    }else {
      return ' - ';
    }
  }

  getType(){
    if(this.filter.type ) {
      return this.filter.type;
    }else {
      return ' - ';
    }
  }

  getAreas(){
    if(this.filter.area.length > 0 ) {
      return this.filter.area;
    }else {
      return ' - ';
    }
  }

  public optionsArea = CheckboxOption.fromDictChoices(
    'energy, sustainability and smart building',
    'future services',
    'health',
    'ICT',
    'life science',
    'sustainable production technics and industry 4.0',
    'other');

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth < 1400){
      this.enableBreak = true;
    }else{
      this.enableBreak = false;

    }
  }

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, public authService: AuthService, private persistenceService: PersistenceService) {
  }

  onPrint(b: Benchlearning){
    this.router.navigate(['benchlearning/pdf/'+b.id]);

  }

  showFilter(){
    this.filterVisible = true;
    this.showFilterVisible = false;
  }

  compareROs(b: Benchlearning){
    localStorage.setItem("compare",JSON.stringify(b));
    this.router.navigate(['/benchlearning/compare'])
  }

  clearFilter(){
    this.sortedList = this.benchlearningList;
    this.filterVisible = false;
    this.showFilterVisible = true;
    this.filter.type = '';
    this.filter.obsTechnology = false;
    this.filter.obsPolicy = false;
    this.filter.area = [];
  }

  filterList(){
    this.showFilterVisible = true;
    this.filterVisible = true;
    setTimeout( ()=> {
      var tmpList: Benchlearning[] = [];
      for(let o of this.benchlearningList){
        var filter = true;
        if(this.filter.type === 'any'){
          filter = true;
        }else if (this.filter.type !== '' && o.type !== this.filter.type) {
          filter = false;
        }
        if (this.filter.obsTechnology !== false || this.filter.obsPolicy !== false) {
          if (this.filter.obsPolicy === true && this.filter.obsTechnology === true) {
            if (o.obsTechnology === true && o.obsPolicy === true) {

            } else {
              filter = false;
            }
          } else if (this.filter.obsPolicy === true && o.obsPolicy === true) {

          } else if (this.filter.obsTechnology === true && o.obsTechnology === true) {

          } else {
            filter = false;
          }
        }
        if (this.filter.area.length > 0) {
          var exist = false;
          for (let a of this.filter.area) {
            if (o.areaSmartSpec.indexOf(a) > -1) {
              exist = true;
              break;
            } else {
              exist = false;
            }
          }
          if (exist === false) {
            filter = false;
          }
        }

        if (filter === true) {
          tmpList.push(o);
        }
      }
      this.sortedList = tmpList;
      this.amountRes = this.sortedList.length;
    },100);

  }

  getCurrentDate(): string {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  onShowDetail(b: Benchlearning){
    //show detail data for the selected RO (data are defined in an excel sheet)
    localStorage.setItem("element",JSON.stringify(b));
    this.router.navigate(['/benchlearning/detail'])
  }
}

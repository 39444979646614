
import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions, Response} from '@angular/http';



import {Subject} from 'rxjs';
import {User} from "./user";
import {environment} from "../../environments/environment";


@Injectable()
export class AuthService {

  constructor(private http: Http) {

  }
  private counterToken = 0;

  loggedOut = new Subject();
  loggedIn = new Subject();

  public login(name: string, password: string): Promise<User> {
    return this.http
      .post( environment.apiUrl + 'auth/login', JSON.stringify({name: name, password: password, bench: true}), this.generateRequestHeader())
      .toPromise()
      .then((res: Response) => {
        this.saveToken(res);
        this.loggedIn.next();
      })
      .catch (this.handleError);
  }

  public getNewToken(name: string, counter: number): Promise<User> {
    return this.http
      .get( environment.apiUrl + 'auth/token/' + name, this.generateRequestHeaderToken())
      .toPromise()
      .then((res: Response) => {
        this.saveToken(res);
        counter = 0;
      })
      .catch (this.handleError);
  }

  public hasToken(): boolean {
    const hasToken = this.tokenInfo != null;
    return hasToken;
  }

  public createUser(name: string, password: string, role: string, institutionID: string, firstname: string, lastname: string, email:string): Promise<String> {
    return this.http
      .post( environment.apiUrl + 'users', JSON.stringify({name: name, password: password, userRole: role, institutionID: institutionID, firstname: firstname, lastname: lastname, email: email, active: true}), this.generateRequestHeaderToken())
      .toPromise()
      .then((res: Response) => {
        return 'User successfully created';
      })
      .catch (this.handleErrorPw);
  }

  public changePW(passwordOld: string, passwordNew: string, passwordNewRep: string): Promise<String> {
    return this.http
      .post( environment.apiUrl + 'auth/changePassword',
        JSON.stringify({password: passwordOld, passwordNew: passwordNew, passwordNewRep: passwordNewRep}), this.generateRequestHeaderToken())
      .toPromise()
      .then((res: Response) => {
        return 'Password successfully changed';
      })
      .catch (this.handleErrorPw);
  }

  public changeNameMail(firstname: string, lastname: string, email: string): Promise<String> {
    return this.http
      .post( environment.apiUrl + 'auth/changeDetails',
        JSON.stringify({firstname: firstname, lastname: lastname, email: email}), this.generateRequestHeaderToken())
      .toPromise()
      .then((res: Response) => {
        return 'User details successfully updated';
      })
      .catch (this.handleErrorPw);
  }

  public logout(): void {
    sessionStorage.removeItem('user');
    this.loggedOut.next();
  }

  // Saves user details with token into sessionStorage as user item
  private saveToken(res: Response): void {
    const response = res.json() && res.json().token;
    if (response) {
      const token = response;
      const claims = this.getTokenClaims(token);
      claims.token = token;
      sessionStorage.setItem('user', JSON.stringify(claims));
    } else {
      throw Error(res.json());
    }
  }

  public get tokenInfo(): TokenInfo{
    const rawToken = JSON.parse(sessionStorage.getItem('user'));
    if (rawToken == null) {
      return null;
    }
    const claim = this.getTokenClaims(rawToken.token);

    const created = rawToken.iat;
    const expires = rawToken.exp;
    const tokenInfo =  new TokenInfo(rawToken.token, claim.usrid, expires, claim.role.map(r => r.authority));

    if (tokenInfo.isExpired()) {
      this.logout();
      return null;
    }
    if (tokenInfo.expiresSoon()){
      if (claim.usrid && this.counterToken === 0 ) {
        this.counterToken++;
        this.getNewToken(claim.usrid, this.counterToken);
      }
    }

    return tokenInfo;
  }

  public isAdmin(): boolean {
    return (this.tokenInfo != null && this.tokenInfo.roles.toString().indexOf('ADMIN') > -1);
  }

  public isLeadPartner(): boolean {
    return (this.tokenInfo != null && this.tokenInfo.roles.toString().indexOf('LEAD-PARTNER') > -1);
  }

  public isPartner(): boolean {
    return (this.tokenInfo != null && this.tokenInfo.roles.toString().indexOf('PARTNER') > -1);
  }

  public isBenchlearning(): boolean {
    return (this.tokenInfo != null && this.tokenInfo.roles.toString().indexOf('BENCHLEARNING') > -1);
  }

  public isJS(): boolean {
    return (this.tokenInfo != null && this.tokenInfo.roles.toString().indexOf('JS') > -1);
  }

  public isAuditPlus(): boolean {
    return (this.tokenInfo != null && this.tokenInfo.roles.toString().indexOf('AUDITPLUS') > -1);
  }


  public isPartnerOrLeadPartnerOrAdmin(): boolean {
    return (this.isPartner() || this.isLeadPartner() || this.isAdmin() );
  }

  public isPartnerOrLeadPartnerOrAdminOrBenchlearning(): boolean {
    return (this.isPartner() || this.isLeadPartner() || this.isAdmin() || this.isBenchlearning() );
  }

  public isPartnerOrLeadPartnerOrAdminOrBenchlearningOrAuditPlusOrJs(): boolean {
    return (this.isPartner() || this.isLeadPartner() || this.isAdmin() || this.isBenchlearning() || this.isAuditPlus() || this.isJS());
  }

  public isPartnerOrLeadPartnerOrAdminData(data): boolean {
    return ((this.isPartner() && data.length < 1) || this.isLeadPartner()  );
  }
  public isPartnerOrAdmin(): boolean {
    return (this.isPartner() || this.isAdmin() );
  }

  // Retrieves user details from token
  private getTokenClaims(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  private generateRequestHeader(): RequestOptions {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type');
    return new RequestOptions({ headers: headers });
  }
  // Generates Headers
  private generateRequestHeaderToken(): RequestOptions {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type');

    const token = this.tokenInfo;

    if (token != null) {
      headers.set('Authorization', token.rawValue);
    }

    return new RequestOptions({ headers: headers });
  }


  private handleError(error: Response): Promise<any> {
    return Promise.reject(error.json().message);
  }

  private handleErrorPw(error: Response): Promise<any> {
    return JSON.parse(JSON.stringify(error))._body;
  }
}

export class TokenInfo {
  expirationDate: number;
  rawValue: string;
  userId: string;
  roles: string[];
  constructor(rawValue: string, userId: string, expirationEpoch: number, roles: string[]) {
    this.rawValue = rawValue;
    this.userId = userId;
    this.expirationDate = expirationEpoch;
    this.roles = roles;

  }
  private static utcNow(): Date {
    const now = new Date();
    return new Date(now.getUTCFullYear(),
      now.getUTCMonth(), now.getUTCDate(),
      now.getUTCHours(), now.getUTCMinutes(),
      now.getUTCSeconds());
  }
  private static utcNowExpiresSoon(): Date {
    const now = new Date().getTime() + 900000;
    return new Date(now);
  }
  isExpired(): boolean {
    const curTimeSeconds = new Date().getTime()/1000 | 0;
    return this.expirationDate < curTimeSeconds;
  }

  expiresSoon(): boolean {
    const curTime15Minutes = new Date().getTime()/1000 + 900 | 0 ;
    return this.expirationDate < curTime15Minutes;
  }
}

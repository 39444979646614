<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">

        <a *ngIf="region !== ''" routerlink="/competitiveness" href="/competitiveness" mat-button style='color: rgba(255, 255, 255, 0.87)'>

          <span><i class="material-icons" matTooltip="Competitiveness statistic">arrow_back</i> </span>
        </a>
        <span *ngIf="region === ''"> Competitiveness Statistic</span>
        <span *ngIf="region !== ''"> Competitiveness Statistic - {{region}}</span>

      </h1>
    </div>
  </div>
</header>

<mat-card *ngIf="showDropDown">
  <mat-form-field class="input-width">
    <mat-select [(ngModel)]="selectedIndicator"
                placeholder="Choose an indicator"
                id="indicators">
      <mat-option *ngFor="let i of indicators" [value]="i">
        {{i}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-mini-fab color="primary" matTooltip="start compare" (click)="showDiagramForCompetitiveness()" style="margin:5px;"><mat-icon aria-label="filter">done</mat-icon></button>
</mat-card>

<mat-card>

  <div [chart]="chart"></div>

</mat-card>

<mat-card *ngIf="showAllTable">
  <table class="table">
    <tr>
      <th>Region</th>
      <th>2010</th>
      <th>2013</th>
      <th>2017</th>
    </tr>
    <tr *ngFor="let element of competitivenessData">
      <td>{{element.region}}</td>
      <td>{{element.data[position].year2009}}</td>
      <td>{{element.data[position].year2012}}</td>
      <td>{{element.data[position].year2014}}</td>
    </tr>
  </table>
</mat-card>




<mat-card *ngIf="showIndicatorTable" style="margin-bottom: 100px">
  <table class="table">
    <tr>
      <th>2010</th>
      <th>2013</th>
      <th>2017</th>
    </tr>
    <tr>
      <td>{{comp.year2009}}</td>
      <td>{{comp.year2012}}</td>
      <td>{{comp.year2014}}</td>
    </tr>
  </table>
</mat-card>


import {Component, OnInit} from "@angular/core";

import {Register} from "./register";
import {DataService} from "../data-service";

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.css']
})
export class RegisterComponent implements OnInit {

  registration = Register.empty();
  resError$;
  title;

  ngOnInit(): void {

  }

  constructor(public dataService: DataService) {}

  onSubmit() {
      this.dataService.postRegisterUserMail(this.registration).subscribe(
        r => {
          this.resError$ = r;
        }
      );

  }

  regionSelected = {  value: []};
  selectDisabled = true;

  regions = {
    'Austria': {value: [{name: 'Burgenland'}, {name: 'Kärnten'}, {name: 'Niederösterreich'}, {name: 'Oberösterreich'}
      , {name: 'Salzburg'}, {name: 'Steiermark'}, {name: 'Tirol'}, {name: 'Vorarlberg'}, {name: 'Wien'}]},
    'CzechRepublic' : {value:  [{name: 'Jihovychod'}, {name: 'Jihozapad'}, {name: 'Moravskoslezsko'}, {name: 'Praha'},
      {name: 'Severovychod'}, {name: 'Severozapad'}, {name: 'Stredni Cechy'}, {name: 'Stredni Morava'}]},
    'Germany': {value: [{name: 'Baden-Württemberg'}, {name: 'Bayern'}, {name: 'Berlin'}, {name: 'Brandenburg'}, {name: 'Bremen'},
      {name: 'Hamburg'}, {name: 'Hessen'}, {name: 'Mecklenburg-Vorpommern'}, {name: 'Niedersachsen'}, {name: 'Nordrhein-Westfalen'},
      {name: 'Rheinland-Pfalz'}, {name: 'Saarland'}, {name: 'Sachsen'}, {name: 'Sachsen-Anhalt'}, {name: 'Schleswig-Holstein'}, {name: 'Thüringen'}]},
    'Hungary': {value: [{name: 'Del-Alfold'}, {name: 'Del-Dunantul'}, {name: 'Eszak-Alfold'}, {name: 'Eszak-Magyarorszag'}, {name: 'Kozep-Dunantul'},
      {name: 'Kozep-Magyarorszag'}, {name: 'Nyugat-Dunantul'}]},
    'Italy': {value: [{name: 'Abruzzo'}, {name: 'Basilicata'}, {name: 'Calabria'}, {name: 'Campania'}, {name: 'Emilia-Romagna'},
      {name: 'Friuli-Venezia Giulia'}, {name: 'Lazio'}, {name: 'Liguria'}, {name: 'Lombardia'},
      {name: 'Marche'}, {name: 'Molise'}, {name: 'Piemonte'},
      {name: 'Provincia Autonoma Trento'}, {name: 'Puglia'}, {name: 'Sardegna'}, {name: 'Sicilia'},
      {name: 'Toscana'}, {name: 'Umbria'}, {name: 'Valle d\'Aosta/Vallée d\'Aoste'}, {name: 'Veneto'}]},
    'Poland': {value: [{name: 'Dolnoslaskie'}, {name: 'Kujawsko-Pomorskie'}, {name: 'Lodzkie'}, {name: 'Lubelskie'}, {name: 'Lubuskie'},
      {name: 'Malopolskie'}, {name: 'Mazowieckie'}, {name: 'Opolskie'}, {name: 'Podkarpackie'},
      {name: 'Podlaskie'}, {name: 'Pomorskie'}, {name: 'Slaskie'}, {name: 'Swietokrzyskie'}, {name: 'Warminsko-Mazurskie'}, {name: 'Wielkopolskie'}, {name: 'Zachodniopomorskie'}]},
    'Slovenia': {value: [{name: 'Vzhodna Slovenija'}, {name: 'Zahodna Slovenija'}]}
  };

  setRegions(): void {
    this.selectDisabled = false;
    switch (this.registration.country) {
      case 'Austria': {
        this.regionSelected = {value: this.regions.Austria.value};
        break;
      }
      case 'Czech Republic': {
        this.regionSelected = {value: this.regions.CzechRepublic.value};
        break;
      }
      case 'Germany': {
        this.regionSelected = {value: this.regions.Germany.value};
        break;
      }
      case 'Hungary': {
        this.regionSelected = {value: this.regions.Hungary.value};
        break;
      }
      case 'Italy': {
        this.regionSelected = {value: this.regions.Italy.value};
        break;
      }
      case 'Poland': {
        this.regionSelected = {value: this.regions.Poland.value};
        break;
      }
      case 'Slovenia': {
        this.regionSelected = {value: this.regions.Slovenia.value};
        break;
      }
    }
  }


}

<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">Login</h1>
    </div>
  </div>
</header>
<mat-card class="mat-card-align">
  <mat-error>{{resError$}}</mat-error>
  <form novalidate (ngSubmit)="onSubmit(userForm.value, userForm.valid)" [formGroup]="userForm">
    <div fxLayout="column" fxLayoutAlign="space-around strech">

      <mat-form-field class="input-width">
        <input matInput id="username" formControlName="name" placeholder="Username">
        <mat-error *ngIf="userForm.get('name').hasError('required') && userForm.get('name').touched">
          Username is required.
        </mat-error>
        <mat-error *ngIf="userForm.get('name').hasError('minlength') && userForm.get('name').touched">
          Minimum of 5 characters.
        </mat-error>
      </mat-form-field>
<br>
      <mat-form-field class="input-width">
        <input matInput type="password" id="password" formControlName="password" placeholder="Password">
        <mat-error *ngIf="userForm.get('password').hasError('required') && userForm.get('password').touched">
          Password is required.
        </mat-error>
      </mat-form-field>

      <mat-card-actions class="input-width">
        <button class="btn-align" mat-fab type="submit" [disabled]="!userForm.valid"><i class="material-icons">done</i></button>
      </mat-card-actions>

    </div>
  </form>
</mat-card>





import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {MaterialModule} from '../material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RouterModule} from "@angular/router";
import {MatDialogModule, MatExpansionModule, MatTooltipModule} from "@angular/material";
import {DataService} from "../data-service";
import {BenchmarkingComponent} from "./benchmarking.component";
import {BenchlearningDetailComponent} from "./detail/benchlearning-detail.component";
import {PrintBenchlearningPdfComponent} from "./printPdf/printPdf.component";
import {MultipleChoiceCheckboxComponent} from "../common/multiple-choice-checkbox.component";
import {CompareOverviewComponent} from "./compare/compare_overview.component";
import {ChartModule, HIGHCHARTS_MODULES} from "angular-highcharts";
import {Popup_infoComponent} from "./compare/popup_info.component";
import {PersistenceModule} from "angular-persistence";
import {CommonComponentsModule} from "../common/common.module";


@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpModule,
    FormsModule,
    MatTooltipModule,
    MatExpansionModule,
    ChartModule,
    MatDialogModule,
    PersistenceModule,
    CommonComponentsModule
  ],
  exports: [
    BenchmarkingComponent,
    BenchlearningDetailComponent,
    PrintBenchlearningPdfComponent,
    CompareOverviewComponent,
  ],
  declarations: [
    BenchmarkingComponent,
    BenchlearningDetailComponent,
    PrintBenchlearningPdfComponent,
    CompareOverviewComponent,
    Popup_infoComponent
  ],
  entryComponents: [
    Popup_infoComponent

  ],
  providers: [
    DataService,
  ]
})
export class BenchmarkingModule {}

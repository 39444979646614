import {Injectable} from '@angular/core';
import {HttpClient,} from '@angular/common/http';
import {HTTPServiceBase} from "./httpServiceBase";
import {AuthService} from "./auth/auth.service";
import {NetworkStatusService} from "./network-status/network-status-service";
import { Observable } from 'rxjs/Observable';
import "rxjs/add/operator/share";
import {Benchlearning} from "./benchmarking/benchmarking";
import {PdfParameter, PdfParameterRequest} from "./benchmarking/printPdf/pdfParameter";
import {SimilarityResult} from "./benchmarking/compare/SimilarityResult";
import {RegionSector} from "./framework/region_sector/region_sector";
import {Innovation} from "./framework/innovation/innovation";
import {Competitiveness} from "./framework/competitiveness/competitiveness";
import {RegionalEconomy} from "./framework/regional_economy/regionalEconomy";
import {SimilarityDemandResponse} from "./demand-overlay/similarityDemandResponse";
import {ObsSimilarityDemandResponse} from "./demand-overlay/obsSimilariryDemandResponse";
import {Overlay} from "./overlay/swOverlay";
import {Register} from "./auth/register";
import {Insititution} from "./auth/createInstitution/insititution";
import {User} from "./auth/user";


@Injectable()
export class DataService extends HTTPServiceBase {

  constructor(httpClient: HttpClient, authService: AuthService, networkStatus: NetworkStatusService) {
    super(httpClient, authService, networkStatus);
  }

  public getBenchlearning(): Observable<Benchlearning[]> {
      return super.get('benchlearning');
  }

  public getDataDemandRegionAllObservatories(region: string): Observable<SimilarityDemandResponse>{
    return this.get('similarityN/demand/all/'+region);
  }

  public getDataDemandObservatory(id: string): Observable<ObsSimilarityDemandResponse>{
    return this.get('similarityN/demand/'+id);
  }

  public getDataDemandAuditData(): Observable<SimilarityDemandResponse[]> {
    return this.get('similarityN/demand');
  }

  public postRegisterUserMail(registration: Register){
    return super.post('auth/register',registration);
  }

  public getDataOverlay(): Observable<Overlay[]> {
    return this.get('benchlearning/overlay');
  }

  public putBenchlearningReportParameter(id: string, data: PdfParameterRequest): Observable<PdfParameter>{
    return super.put('benchlearning/pdf/'+id,data);
  }

  public getPdfData(id: string): Observable<PdfParameter>{
    return super.get('benchlearning/pdf/'+id);
  }

  public printBenchlearningReport(id: string): Observable<Blob> {
    return super.getPdf('benchlearning/pdf/' + id);
  }


  getDataCompareAll(id: string) : Observable<SimilarityResult[]>{
    return super.get('similarity/'+id);
  }

  getDataCompareTwo(id: string, idS: string): Observable<SimilarityResult>{
    return super.get('similarity/'+id+'/'+idS);
  }

  getStatisticDataRegionSector(): Observable<RegionSector[]>{
    return super.get('statistic/sectors');
  }

  getStatisticDataInnovation(): Observable<Innovation[]>{
    return super.get('statistic/innovation');
  }

  getStatisticDataCompetitiveness(): Observable<Competitiveness[]>{
    return super.get('statistic/competitiveness');
  }

  getStatisticDataRegionalEconomy(): Observable<RegionalEconomy[]>{
    return super.get('statistic/regionalEconomy');
  }

  public getUsers(): Observable<User[]> {
    return super.get('admin/users/');
  }

  public getUser(id:string): Observable<User> {
    return super.get('admin/users/'+id);
  }

  public updateUser(id:string,user:User): Observable<User> {
    return super.put('admin/users/'+id,user);
  }


  public deleteUser(id: string): Observable<String> {
    return super.delete('admin/users/'+id);
  }

  public createInsitution(data: Insititution): Observable<Insititution>{
    return super.post('institution',data);
  }

  public getInsitution(): Observable<Insititution[]>{
    return super.get('institution');
  }

  public getSingleInsitution(id: string): Observable<Insititution>{
    return super.get('institution/'+id);
  }

  public deleteInsitution(id: string): Observable<String>{
    return super.delete('institution/'+id);
  }

  public updateInstitution(id: string,data: Insititution): Observable<Insititution>{
    return super.put('institution/'+id,data)
  }

}

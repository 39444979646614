<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">Update Account</h1>
    </div>
  </div>
</header>
<h1 _ngcontent-c9="" class="mat-h1">Change Password</h1>
<mat-card class="mat-card-align">
  <mat-error>{{resError$}}</mat-error>
  <div>{{resp}}</div>
  <form novalidate (ngSubmit)="onSubmit(pwForm.value, pwForm.valid)" [formGroup]="pwForm">
    <div fxLayout="column" fxLayoutAlign="space-around strech">

      <mat-form-field class="input-width">
        <input matInput type="password" id="passwordOld" formControlName="passwordOld" placeholder="Old Password">
        <mat-error *ngIf="pwForm.get('passwordOld').hasError('required') && pwForm.get('passwordOld').touched">
          Old password is required.
        </mat-error>
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <input matInput type="password" id="passwordNew" formControlName="passwordNew" placeholder="New Password">
        <mat-error *ngIf="pwForm.get('passwordNew').hasError('required') && pwForm.get('passwordNew').touched">
          New password is required.
        </mat-error>
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <input matInput type="password" id="passwordNewRep" formControlName="passwordNewRep" placeholder="Repetition Password">
        <mat-error *ngIf="pwForm.get('passwordNewRep').hasError('required') && pwForm.get('passwordNewRep').touched">
          Repetition of the password is required.
        </mat-error>
      </mat-form-field><br>

      <mat-card-actions class="input-width">
        <button class="btn-align" mat-fab type="submit" [disabled]="!pwForm.valid"><i class="material-icons">done</i></button>
      </mat-card-actions>

    </div>
  </form>
</mat-card>
<h1 _ngcontent-c9="" class="mat-h1">Change Details</h1>
<mat-card class="mat-card-align">
  <div>{{resErrorName$}}</div>
  <div>{{resSuc$}}</div>
  <form novalidate (ngSubmit)="onSubmit(pwForm.value, pwForm.valid)" [formGroup]="pwForm">
    <div fxLayout="column" fxLayoutAlign="space-around strech">

      <mat-form-field class="input-width">
        <input matInput type="text" id="firstname" formControlName="firstname" placeholder="Firstname">
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <input matInput type="text" id="lastname" formControlName="lastname" placeholder="Lastname">
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <input matInput type="text" id="email" formControlName="email" placeholder="Mail">
      </mat-form-field><br>

      <mat-card-actions class="input-width">
        <button class="btn-align" mat-fab type="submit"><i class="material-icons">done</i></button>
      </mat-card-actions>


    </div>
  </form>
</mat-card>



<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">Update User</h1>
    </div>
  </div>
</header>
<mat-card class="mat-card-align">
  <mat-error>{{resError$}}</mat-error>
  <h3>{{userName}}</h3>

  <mat-form-field class="input-width">
    <textarea matInput id="firstname" [(ngModel)]="user.firstname" placeholder="Firstname"
              class="input-width"></textarea>
  </mat-form-field>
  <br>

  <mat-form-field class="input-width">
    <textarea matInput id="lastname" [(ngModel)]="user.lastname" placeholder="Lastname" class="input-width"></textarea>
  </mat-form-field>
  <br>

  <mat-form-field class="input-width">
    <textarea matInput id="email" [(ngModel)]="user.email" placeholder="Email" class="input-width"></textarea>
  </mat-form-field>
  <br>

  <mat-form-field class="input-width">
    <textarea matInput id="password" [(ngModel)]="user.password" placeholder="Password" class="input-width"></textarea>
  </mat-form-field>
  <br>

  <mat-form-field class="input-width">
    <mat-select matInput placeholder="Role" id="role" [(ngModel)]="user.userRole">
      <mat-option value="AUDITPLUS">AUDITPLUS</mat-option>
      <mat-option value="AUDITOR">AUDITOR</mat-option>
      <mat-option value="BENCHLEARNING">BENCHLEARNING</mat-option>
      <mat-option value="PARTNER" *ngIf="authService.isAdmin()">PARTNER</mat-option>
      <mat-option value="LEAD-PARTNER" *ngIf="authService.isAdmin()">LEAD-PARTNER</mat-option>
      <mat-option value="ADMIN" *ngIf="authService.isAdmin()">ADMIN</mat-option>
    </mat-select>
  </mat-form-field>
  <br>


  <mat-form-field class="input-width" *ngIf="authService.isAdmin()">
    <mat-select matInput placeholder="Institution" id="institution" [(ngModel)]="user.institutionID">
      <mat-option *ngFor="let ins of institutions" [value]="ins.id">
        {{ins.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>

  <mat-card-actions class="input-width">
    <button class="btn-align" mat-fab type="submit" (click)="onSubmit()"><i class="material-icons">done</i></button>
  </mat-card-actions>
  <br>

</mat-card>




import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "./auth.service";
import {User} from "./user";

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  userForm: FormGroup;
  user = User.empty();
  resError$;

  ngOnInit(): void {
    this.userForm = new FormGroup({
      'name': new FormControl(this.user.name, [Validators.required, Validators.minLength(5)]),
      'password': new FormControl(this.user.password, Validators.required),
    });
  }

  constructor(private authService: AuthService, private router: Router) {}

  onSubmit(model: User, isValid: boolean) {
  //  console.log(model, isValid);
    this.user.name = model.name;
    this.user.password = model.password;
    this.authService.login(this.user.name, this.user.password)
      .then(() => {
        this.router.navigateByUrl('/benchlearning');
      }).catch((error: any ) => {
        this.resError$ = error;
        throw error;
      });
  }
}

export class ObsSimilarityDemandResponse{
  id: string = '';
  obsName: string = '';
  avgSimilarity: number = 0.0;
  avgSimilarityNeg: number = 0.0;
  responses: ObsSimilarityResponse[] = [];
}

export class ObsSimilarityResponse{
  region: string = '';
  country: string = '';
  resultsPos: number[] = [];
  resultsNeg: number[] = [];
}


import {Component} from "@angular/core";
import {AuthService} from "../auth/auth.service";



@Component({
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent {
  constructor(public authService: AuthService) {
    this.authService = authService;
  }
  }





    <mat-radio-group>
      <ng-container *ngFor="let opt of _options; let i = index">
        <mat-checkbox  [(ngModel)]="opt.selected" (ngModelChange)="selectionChanged()"  style="margin-left: 10px;"
                      [value]="opt.value">{{opt.displayValue}}
        </mat-checkbox>
        <mat-form-field class="input-width"  *ngIf="comments && opt.selected" >
    <textarea matInput  (blur)="commentTextChanged()" [(ngModel)]="_dictSelected[opt.value]"
              placeholder="Please specify:"></textarea>
        </mat-form-field> <br>
      </ng-container>

    </mat-radio-group>
  
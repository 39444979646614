<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1"><img src="assets/SMART_watch_RGB.png" style="width:100%;height:auto;max-width:700px;"></h1>
      <h2 _ngcontent-c9="">Benchmarking</h2>

    </div>

    <a   routerlink="/benchlearning" *ngIf="authService.hasToken() && authService.isPartnerOrLeadPartnerOrAdmin()"  href="./benchlearning" routerLinkActive="active" _ngcontent-c1="" mat-button="" >
      <mat-icon style='color: rgba(0, 0, 0, 0.87)'>input</mat-icon> <span style='color:rgba(0, 0, 0, 0.87)'>GOTO Benchlearning</span>
    </a>
    <h6 style='max-width:700px;color: rgba(0, 0, 0, 0.87); text-align:left;width:50%;margin:auto;'>
      Dear Visitor,<br>
      If you would like to join the network of regional branch observatories of intelligent markets in Central Europe or you want to access the benchmarking data, please register.<br><br>

      If you are interested in receiving more information on the SMART_watch project, please contact us:  <a style='color: rgba(0, 0, 0, 0.87);font-weight:bold' href="mailto:SMART_watch@gapr.pl">SMART_watch@gapr.pl</a>.
    </h6>
  </div>
</header>



export class Filter {
  obsPolicy: boolean;
  obsTechnology: boolean;
  type: string;
  area: string[];

  static empty(): Filter {
    return {
      obsPolicy: false,
      obsTechnology: false,
      type: '',
      area: []
    };
  }
}

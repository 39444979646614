
import {Component, OnInit} from "@angular/core";
import {Chart} from "angular-highcharts";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material";
import {DataService} from "../../data-service";
import {InnovationData} from "./innovation";

@Component({
  templateUrl: './innovation.component.html',
  styleUrls: ['../framework.css'],
})
export class InnovationComponent implements OnInit {
  selectedIndicator = 'Population with tertiary education (ISCED 5-6) per 100 population aged 25-64 (restricted 30-34 for year 2017)';
  indicators = ['Population with tertiary education (ISCED 5-6) per 100 population aged 25-64 (restricted 30-34 for year 2017)',
  'Participation in life-long learning per 100 population aged 25-64', 'International scientific co-publications per million population',
  'R&D expenditure in the public sector as % of GDP', 'R&D expenditure in the business sector as % of GDP', 'EPO patent applications per billion Regional GDP (PPS€)',
  'Employment MHT manufacturing/KIS services', 'SMEs introducing product or process innovations as % of SMEs', 'SMEs introducing marketing or organizational innovations as % of SMEs',
  'SMEs innovating in-house as a percentage of all SMEs', 'Non-R&D innovation expenditures as percentage of total turnover'];

  innovationData;
  showDropDown = true;
  region = '';
  showAllTable = true;
  showPartnerTable = false;
  showIndicatorTable = false;
  position = 0;

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.dataService.getStatisticDataInnovation().subscribe(res=>{
      this.innovationData = res;
      for(let e of this.innovationData){
        let tmp = e.region;
        this.chart.addSeries({type: 'column', name: e.region, data: [{name: '2009', y: e.data[0].year2009},
          {name: '2012', y: e.data[0].year2012}, {name: '2014' , y: e.data[0].year2014}, {name: '2017' , y: e.data[0].year2017}], events: {
          click: (e) => {
            this.region = tmp;
            this.drawDiagramForPartner(tmp)
          }
        }}, true, false);
      }    })

  }


  showDiagramForInnovation(){
    this.initializeChart()
    this.showAllTable = true;
    this.showPartnerTable = false;
    this.showIndicatorTable = false;

    let pos = this.getNumberOfInnovation(this.selectedIndicator);
    this.position = pos;
    for(let e of this.innovationData){
      let tmp = e.region;
      this.chart.addSeries({type: 'column', name: e.region, data: [{name: '2009', y: e.data[pos].year2009},
        {name: '2012', y: e.data[pos].year2012}, {name: '2014' , y: e.data[pos].year2014}, {name: '2017' , y: e.data[pos].year2017}], events: {
        click: (e) => {
          this.region = tmp;
          this.drawDiagramForPartner(tmp)
        }
      }}, true, false);
    }
  }

  getNumberOfInnovation(indicator): number{
    switch (indicator) {
      case 'Population with tertiary education (ISCED 5-6) per 100 population aged 25-64 (restricted 30-34 for year 2017)':
        return 0;
      case 'Participation in life-long learning per 100 population aged 25-64':
        return 1;
      case 'International scientific co-publications per million population':
        return 2;
      case 'R&D expenditure in the public sector as % of GDP':
        return 3;
      case 'R&D expenditure in the business sector as % of GDP':
        return 4;
      case 'EPO patent applications per billion Regional GDP (PPS€)':
        return 5;
      case 'Employment MHT manufacturing/KIS services':
        return 6;
      case 'SMEs introducing product or process innovations as % of SMEs':
        return 7;
      case 'SMEs introducing marketing or organizational innovations as % of SMEs':
        return 8;
      case 'SMEs innovating in-house as a percentage of all SMEs':
        return 9;
      case 'Non-R&D innovation expenditures as percentage of total turnover':
        return 10;
      default:
        return 0;
    }
  }


  getNameOfInnovation(indicator): string{
    switch (indicator) {
      case 0:
        return 'Population with tertiary education (ISCED 5-6) per 100 population aged 25-64 (restricted 30-34 for year 2017)'
      case 1:
        return 'Participation in life-long learning per 100 population aged 25-64';
      case 2:
        return 'International scientific co-publications per million population';
      case 3:
        return 'R&D expenditure in the public sector as % of GDP';
      case 4:
        return 'R&D expenditure in the business sector as % of GDP';
      case 5:
        return 'EPO patent applications per billion Regional GDP (PPS€)';
      case 6:
        return 'Employment MHT manufacturing/KIS services';
      case 7:
        return 'SMEs introducing product or process innovations as % of SMEs';
      case 8:
        return 'SMEs introducing marketing or organizational innovations as % of SMEs';
      case 9:
        return 'SMEs innovating in-house as a percentage of all SMEs';
      case 10:
        return 'Non-R&D innovation expenditures as percentage of total turnover';
      default:
        return '';
    }
  }

  innovationDatas: InnovationData[] = [];
  drawDiagramForPartner(region){
    this.initializeChart();
    this.showDropDown = false;

    this.showAllTable = false;
    this.showPartnerTable = true;
    this.showIndicatorTable = false;

  //  this.chart.options.title.text = 'Innovation statistic data ' + region
    for (let c of this.innovationData) {
      if(c.region === region){
        for(let i = 0; i <= 10; i++) {
          this.innovationDatas[i] = new InnovationData(c.data[i].year2009,c.data[i].year2012,c.data[i].year2014,c.data[i].year2017);
          this.chart.addSeries({
            type: 'column',
            name: this.getNameOfInnovation(i),
            data: [{name: '2009', y: c.data[i].year2009}, {name: '2012', y: c.data[i].year2012},{name: '2014', y: c.data[i].year2014}, {name: '2017', y: c.data[i].year2017}],
            events: {
              click: (e) => {
                this.drawDiagramForIndicator(this.getNameOfInnovation(i),i, region)
              }
            }
          }, true, false)
        }
      }
    }
  }

  innovationDataElement = new InnovationData(0,0,0,0);
  drawDiagramForIndicator(indicator, index, region){
    this.showAllTable = false;
    this.showPartnerTable = false;
    this.showIndicatorTable = true;

    this.initializeChartLine('Innovation statistic data ' + indicator);

    for (let c of this.innovationData) {
      if (c.region === region) {
        this.innovationDataElement.year2009 = c.data[index].year2009;
        this.innovationDataElement.year2012 = c.data[index].year2012;
        this.innovationDataElement.year2014 = c.data[index].year2014;
        this.innovationDataElement.year2017 = c.data[index].year2017;

        this.chart.addSeries({
          type: 'column',
          name: indicator,
          data: [{name: '2009', y: c.data[index].year2009}, {name: '2012', y: c.data[index].year2012},{name: '2014', y: c.data[index].year2014}, {
            name: '2017', y: c.data[index].year2017}]
        }, true, false)
      }
    }
  }

  initializeChart(){
    this.chart = new Chart( {chart: {
      type: 'column'
    },title: {
      text: 'Innovation statistic data'
    },
      xAxis: {
        title: {
          text: ''
        },
        labels:
          {
            enabled: true
          },
        categories: ['2009','2012','2014','2017']
      },
      yAxis: {
        title: {
          text: 'Percentage'
        }
      },
      credits: {
        enabled: true
      }
    });

  }

  initializeChartLine(textTitle){
    this.chart = new Chart( {chart: {
      type: 'line'
    },title: {
      text: textTitle
    },
      xAxis: {
        title: {
          text: ''
        },
        labels:
          {
            enabled: true
          },
        categories: ['2009','2012','2014','2017']
      },
      yAxis: {
        title: {
          text: 'Percentage'
        }
      },
      credits: {
        enabled: true
      }
    });

  }

  chart = new Chart( {chart: {
    type: 'column'
  },title: {
  text: 'Innovation statistic data'
  },
    xAxis: {
      title: {
        text: ''
      },
      labels:
        {
          enabled: true
        },
      categories: ['2009','2012','2014','2017']
    },
    yAxis: {
      title: {
        text: 'Percentage'
      }
    },
    credits: {
      enabled: true
    }
  });


}

import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {User} from "../user";
import {DataService} from "../../data-service";
import {AuthService} from "../auth.service";

@Component({
  templateUrl: './editUser.component.html',
  styleUrls: ['./editUser.component.css']
})
export class EditUserComponent implements OnInit {


  resError$;
  user: User = User.empty();
  userName = "";
  institutions = [];

  ngOnInit(): void {
    this.dataService.getInsitution().subscribe(r => {
      this.institutions = r;

    this.dataService.getUser(this.router.url.split('/')[2]).subscribe(r => {
      this.user = r;
      this.user.userRole = JSON.parse(JSON.stringify(this.user.userRole)).label;
      this.user.password = '';
      this.userName = this.user.name;
    });
    });
  }

  constructor(public authService: AuthService, private router: Router, private dataService: DataService) {
  }


  onSubmit() {
    this.dataService.updateUser(this.router.url.split('/')[2], this.user).subscribe(r => {
      if (r.name == this.user.name) {
        this.router.navigate(['/manageUser']);
      }else {
        this.resError$ = 'An error during the user update occurred';
      }
    });
  }
}

<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">Create User</h1>
    </div>
  </div>
</header>
<mat-card class="mat-card-align">
  <div>{{resError$}}</div>
  <form novalidate (ngSubmit)="onSubmit(userForm.value, userForm.valid)" [formGroup]="userForm">
    <div fxLayout="column" fxLayoutAlign="space-around strech">

      <mat-form-field class="input-width">
        <input matInput id="name" formControlName="name" placeholder="Username">
        <mat-error *ngIf="userForm.get('name').hasError('required') && userForm.get('name').touched">
          Username is required.
        </mat-error>
        <mat-error *ngIf="userForm.get('name').hasError('minlength') && userForm.get('name').touched">
          Minimum of 5 characters.
        </mat-error>
        <mat-error *ngIf="userForm.get('name').hasError('pattern') && userForm.get('name').touched">
          Username should not contain dots.
        </mat-error>
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <input matInput type="password" id="password" formControlName="password" placeholder="Password">
        <mat-error *ngIf="userForm.get('password').hasError('required') && userForm.get('password').touched">
          New password is required.
        </mat-error>
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <input matInput id="firstname" formControlName="firstname" placeholder="Firstname">
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <input matInput id="lastname" formControlName="lastname" placeholder="Lastname">
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <input matInput id="email" formControlName="email" placeholder="Mail">
      </mat-form-field><br>

      <mat-form-field class="input-width">
        <mat-select matInput placeholder="Role"  id="role" formControlName="role">
          <mat-option value="AUDITOR">AUDITOR</mat-option>
          <mat-option value="BENCHLEARNING">BENCHLEARNING</mat-option>
          <mat-option value="AUDITPLUS">AUDITPLUS</mat-option>
          <mat-option value="PARTNER" *ngIf="authService.isAdmin()">PARTNER</mat-option>
          <mat-option value="LEAD-PARTNER" *ngIf="authService.isAdmin()">LEAD-PARTNER</mat-option>
          <mat-option value="ADMIN" *ngIf="authService.isAdmin()">ADMIN</mat-option>
        </mat-select>
      </mat-form-field><br>

      <mat-form-field class="input-width"  *ngIf="authService.isAdmin()">
        <mat-select matInput placeholder="Institution"  id="institution" formControlName="institutionID">
          <mat-option *ngFor="let ins of institutions" [value]="ins.id">
            {{ins.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-card-actions class="input-width">
        <button class="btn-align" mat-fab type="submit" [disabled]="!userForm.valid"><i class="material-icons">done</i></button>
      </mat-card-actions>

    </div>
  </form>
</mat-card>



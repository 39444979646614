import {DataService} from "../data-service";

declare var require: any;

import {Component, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {Filter} from "./filter_category";
import {Chart} from "angular-highcharts";
import {Overlay, OverlayItem} from "./swOverlay";
import {PopupInfoComponent} from "./popup_info.component";
import {PopupDataComponent} from "./popup_data.component";
import {CheckboxOption} from "../common/multiple-choice-checkbox.component";

@Component({
  selector: 'sw-overlay',
  templateUrl: 'swOverlay.component.html',
  styleUrls: ['swOverlay.component.css']
})

export class SwOverlayComponent implements OnInit {
  //data from the summary tables in D.T2.1.1 and D.T2.1.2
  overlayData: Overlay[] = [];
  chartsArray: Chart[] = [];
  filter = new Filter();
  currentValueOptions: CheckboxOption[] = CheckboxOption.fromChoices(
    'health',
    'life science',
    'ICT',
    'future services',
    'sustainable production technics and industry 4.0',
    'energy, sustainability and smart building',
    'other'
  );

  constructor(private dataService: DataService, private route: ActivatedRoute, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.filter.regions = ['All', 'Del-Alfold', 'Eszak-Alfold', 'Jihozapad', 'Lubelskie', 'Mecklenburg-Vorpommern', 'Piemonte', 'Slaskie', 'Steiermark', 'Veneto', 'Vzhodna Slovenija']
    this.dataService.getDataOverlay().subscribe(res => {
      this.overlayData = res;

      var pos = 0;
      for (let r of this.overlayData) {
        var tmpArray: number[] = this.fillArray(r);

        //the maximum should change depending on the current filter, categories of the summary tables
        this.chartsArray[pos] = this.createChart(r.region, this.calculateMaxValue(), ['DATASET', 'TRENDS', 'REPORTS', 'SERVICES', 'PLATFORM'], 150);
        this.chartsArray[pos].addSeries({
          name: 'DATASET',
          type: 'column',
          color: 'rgb(51, 153, 255)',
          showInLegend: false,
          data: [{name: 'DATASET', y: tmpArray[0]}],
          events: {
            click: (e) => {
              for (var i of r.dataItems) {
                if (i.categorie === 'DATASET') {
                  this.openDataDialog(i);
                }
              }
            }
          }
        }, true, false)
        this.chartsArray[pos].addSeries({
          name: 'TRENDS',
          type: 'column',
          showInLegend: false,
          color: 'rgb(102, 51, 0)',
          data: [{name: 'TRENDS', y: tmpArray[1]}],
          events: {
            click: (e) => {
              for (var i of r.dataItems) {
                if (i.categorie === 'TRENDS') {
                  this.openDataDialog(i);
                }
              }
            }
          }
        }, true, false)
        this.chartsArray[pos].addSeries({
          name: 'REPORTS',
          type: 'column',
          showInLegend: false,
          color: 'rgb(255, 173, 51)',
          data: [{name: 'REPORTS', y: tmpArray[2]}],
          events: {
            click: (e) => {
              for (var i of r.dataItems) {
                if (i.categorie === 'REPORTS') {
                  this.openDataDialog(i);
                }
              }
            }
          }
        }, true, false)
        this.chartsArray[pos].addSeries({
          name: 'SERVICES',
          type: 'column',
          showInLegend: false,
          color: 'rgb(102, 102, 153)',
          data: [{name: 'SERVICES', y: tmpArray[3]}],
          events: {
            click: (e) => {
              for (var i of r.dataItems) {
                if (i.categorie === 'SERVICES') {
                  this.openDataDialog(i);
                }
              }
            }
          }
        }, true, false)
        this.chartsArray[pos].addSeries({
          name: 'PLATFORM',
          type: 'column',
          showInLegend: false,
          color: 'rgb(204, 0, 0)',
          data: [{name: 'PLATFORM', y: tmpArray[4]}],
          events: {
            click: (e) => {
              for (var i of r.dataItems) {
                if (i.categorie === 'PLATFORM') {
                  this.openDataDialog(i);
                }
              }
            }
          }
        }, true, false)

        pos++;
      }
    })
  }

  fillArray(overlayItem: Overlay): number[] {
    var output = [0, 0, 0, 0, 0]
    for (let o of overlayItem.dataItems) {
      if (o.categorie === 'DATASET') {
        output[0] = o.value;
      } else if (o.categorie === 'TRENDS') {
        output[1] = o.value;
      } else if (o.categorie === 'REPORTS') {
        output[2] = o.value;
      } else if (o.categorie === 'SERVICES') {
        output[3] = o.value;
      } else if (o.categorie === 'PLATFORM') {
        output[4] = o.value;
      }
    }
    return output;
  }

  filterChart() {
    this.chartsArray = []
    var pos = 0;
    let categories = this.checkFilterCategory();
    var addedSerie = false;

    setTimeout(() => {
      var tmpArrayDiagram = [];
      for (let r of this.overlayData) {
        var addedAtLeastOne = false;

        if (!this.filter.region || this.filter.region.toUpperCase() === r.region.toUpperCase() || this.filter.region === 'All') {
          if (this.filter.region === r.region) {
            if (!this.filter.area || this.filter.area.length === 0) {
              tmpArrayDiagram[pos] = this.createChart(r.region, this.calculateMaxValue(), categories, 600);
            } else {
              if (this.checkIfAreaExists(r)) {
                tmpArrayDiagram[pos] = this.createChart(r.region, this.calculateMaxValue(), categories, 600);
              } else {
                continue;
              }
            }
          } else {
            if (!this.filter.area || this.filter.area.length === 0) {
              tmpArrayDiagram[pos] = this.createChart(r.region, this.calculateMaxValue(), categories, 150);
            } else {
              if (this.checkIfAreaExists(r)) {
                tmpArrayDiagram[pos] = this.createChart(r.region, this.calculateMaxValue(), categories, 150);
              } else {
                continue;
              }
            }
          }

          var tmpArray: number[] = this.fillArray(r);

          if (this.checkIfAreaExistsItem(r, 'DATASET', categories)) {
            addedSerie = true;
            tmpArrayDiagram[pos].addSeries({
              name: 'DATASET',
              type: 'column',
              color: 'rgb(51, 153, 255)',
              showInLegend: false,
              data: [{name: 'DATASET', y: tmpArray[0]}],
              events: {
                click: (e) => {
                  for (var i of r.dataItems) {
                    if (i.categorie === 'DATASET') {
                      this.openDataDialog(i);
                    }
                  }
                }
              }
            }, true, false)
          }
          if (this.checkIfAreaExistsItem(r, 'TRENDS', categories)) {
            addedSerie = true;
            tmpArrayDiagram[pos].addSeries({
              name: 'TRENDS',
              type: 'column',
              color: 'rgb(102, 51, 0)',
              showInLegend: false,
              data: [{name: 'TRENDS', y: tmpArray[1]}],
              events: {
                click: (e) => {
                  for (var i of r.dataItems) {
                    if (i.categorie === 'TRENDS') {
                      this.openDataDialog(i);
                    }
                  }
                }
              }
            }, true, false)
          }
          if (this.checkIfAreaExistsItem(r, 'REPORTS', categories)) {
            addedSerie = true;
            tmpArrayDiagram[pos].addSeries({
              name: 'REPORTS',
              type: 'column',
              color: 'rgb(255, 173, 51)',
              showInLegend: false,
              data: [{name: 'REPORTS', y: tmpArray[2]}],
              events: {
                click: (e) => {
                  for (var i of r.dataItems) {
                    if (i.categorie === 'REPORTS') {
                      this.openDataDialog(i);
                    }
                  }
                }
              }
            }, true, false)
          }
          if (this.checkIfAreaExistsItem(r, 'SERVICES', categories)) {
            addedSerie = true;
            tmpArrayDiagram[pos].addSeries({
              name: 'SERVICES',
              type: 'column',
              color: 'rgb(102, 102, 153)',
              showInLegend: false,
              data: [{name: 'SERVICES', y: tmpArray[3]}],
              events: {
                click: (e) => {
                  for (var i of r.dataItems) {
                    if (i.categorie === 'SERVICES') {
                      this.openDataDialog(i);
                    }
                  }
                }
              }
            }, true, false)
          }
          if (this.checkIfAreaExistsItem(r, 'PLATFORM', categories)) {
            addedSerie = true;
            tmpArrayDiagram[pos].addSeries({
              name: 'PLATFORM',
              type: 'column',
              color: 'rgb(204, 0, 0)',
              showInLegend: false,
              data: [{name: 'PLATFORM', y: tmpArray[4]}],
              events: {
                click: (e) => {
                  for (var i of r.dataItems) {
                    if (i.categorie === 'PLATFORM') {
                      this.openDataDialog(i);
                    }
                  }
                }
              }
            }, true, false)
          }
        }
        if (addedSerie) {
          pos++;
          addedSerie = false;
          addedAtLeastOne = true;
        }

        if (addedAtLeastOne && tmpArrayDiagram.length > 0) {
          this.chartsArray.push(tmpArrayDiagram[pos - 1]);
        }
      }


    }, 100);

  }

  checkIfAreaExists(o: Overlay): boolean {
    for (let d of o.dataItems) {
      for (let i of d.area) {
        if (this.filter.area.indexOf(i) > -1) {
          return true;
        }
      }

    }
    return false;
  }

  checkIfAreaExistsItem(o: Overlay, category: string, categories: string[]): boolean {
    if (categories.indexOf(category) > -1) {
      if (this.filter.area && this.filter.area.length > 0) {
        for (let d of o.dataItems) {
          for (let i of d.area) {
            if (this.filter.area.indexOf(i) > -1 && d.categorie === category) {
              return true;
            }
          }
        }
      } else {
        for (let d of o.dataItems) {
            if (d.categorie === category) {
              return true;
            }
        }
      }
    }
    return false;
  }

  openDialogInfo() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    this.dialog.open(PopupInfoComponent, dialogConfig);

  }


  createChart(titleText, maximum, categories, width) {
    return new Chart({
      chart: {
        type: 'column',
        height: 350,
        width: width
      }, title: {
        text: titleText
      },
      xAxis: {
        labels: {
          enabled: false,

          style: {
            color: 'red',
            fontSize: '7px'
          }
        },
        categories: categories
      },
      yAxis: {
        max: maximum,

        title: {
          text: 'Amount'
        }
      },
      credits: {
        enabled: true
      }
    });

  }


  checkFilterCategory() {
    var categories = [];
    if (this.filter.data) {
      categories.push("DATASET");
    }
    if (this.filter.trends) {
      categories.push("TRENDS");
    }
    if (this.filter.report) {
      categories.push("REPORTS");
    }
    if (this.filter.services) {
      categories.push("SERVICES");
    }
    if (this.filter.platform) {
      categories.push('PLATFORM')
    }
    return categories;
  }

  calculateMaxValue() {
    let categories = this.checkFilterCategory();
    var maxValue = 0;
    for (let d of this.overlayData) {
      if (!this.filter.region || this.filter.region.toUpperCase() === d.region.toUpperCase() || this.filter.region === 'All') {
        var catArray = this.getCategory(d);
        var valueArray: number[] = this.fillArray(d);

        if (catArray && categories) {

          if (categories.indexOf('DATASET') > -1 && catArray.indexOf('DATASET') > -1) {
            if (valueArray[0] > maxValue) {
              maxValue = valueArray[0];
            }
          }
        }
        if (categories.indexOf('TRENDS') > -1 && catArray.indexOf('TRENDS') > -1) {
          if (valueArray[1] > maxValue) {
            maxValue = valueArray[1];
          }
        }
        if (categories.indexOf('REPORTS') > -1 && catArray.indexOf('REPORTS') > -1) {
          if (valueArray[2] > maxValue) {
            maxValue = valueArray[2];
          }
        }
        if (categories.indexOf('SERVICES') > -1 && catArray.indexOf('SERVICES') > -1) {
          if (valueArray[3] > maxValue) {
            maxValue = valueArray[3];
          }
        }
        if (categories.indexOf('PLATFORM') > -1 && catArray.indexOf('PLATFORM') > -1) {
          if (valueArray[4] > maxValue) {
            maxValue = valueArray[4];
          }
        }
      }
    }

    return maxValue
  }

  getCategory(overlayItem: Overlay): string[] {
    var output = []
    for (let d of overlayItem.dataItems) {
      output.push(d.categorie);
    }
    return output;
  }

  openDataDialog(overlayDatasetItem: OverlayItem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      categoriesDescription: overlayDatasetItem.categoriesDescription
    };

    this.dialog.open(PopupDataComponent, dialogConfig);
  }


}


    <mat-card>
      <h4>Information about Pearson</h4>
      <p>The aim of the diagram is to show the similarity between all ROs and the single RO, which was selected. If one RO is clicked 
        or selected in the drop down menu, the single sections are presented in comparison to the sections of the preselected RO.</p>
      <h5>Pearson:</h5>
      <p>In statistics, the Pearson correlation coefficient is a measure of the linear correlation between two variables X and Y.
        It gives information about the magnitude of the association, or correlation, as well as the direction of the relationship.
        The result is a value between +1 and −1, where 1 is total positive linear correlation, 0 is no
        linear correlation, and −1 is total negative linear correlation. </p>
      <span>A correlation coefficient indicates the extent to which dots in a scatterplot lie on a straight line.</span>
      <li>Correlations are never lower than -1. A correlation of -1 indicates that the data points in a scatter plot lie exactly on a straight descending line; the two variables are perfectly negatively linearly related.</li>
      <li>A correlation of 0 means that two variables don't have any linear relation whatsoever. However, some non linear relation may exist between the two variables.</li>
      <li> Correlation coefficients are never higher than 1. A correlation coefficient of 1 means that two variables are perfectly positively linearly related; the dots in a scatter plot lie exactly on a straight ascending line.</li>
   <br>
    </mat-card>

  

    <div *ngIf="enableBreak"></div>


    <mat-radio-group>
      <ng-container *ngFor="let opt of _options; let i = index">
        <mat-checkbox *ngIf="!enableBreak && i === 0"  [(ngModel)]="opt.selected" (ngModelChange)="selectionChanged()"  style="margin-left : 250px" class="input-width-radio"
                      [value]="opt.value">{{opt.displayValue}}
        </mat-checkbox>
        <mat-checkbox *ngIf="!enableBreak && i !== 0" [(ngModel)]="opt.selected" (ngModelChange)="selectionChanged()"  style="margin-left: 10px" class="input-width-radio"
                      [value]="opt.value">{{opt.displayValue}}
        </mat-checkbox>
        <mat-checkbox *ngIf="enableBreak" [(ngModel)]="opt.selected" (ngModelChange)="selectionChanged()"  style="margin-left: 250px" class="input-width-radio"
                      [value]="opt.value">{{opt.displayValue}}
        </mat-checkbox>

        <mat-form-field class="input-width"  *ngIf="comments && opt.selected" >
    <textarea matInput  (blur)="commentTextChanged()" [(ngModel)]="_dictSelected[opt.value]"
              placeholder="Please specify:"></textarea>
        </mat-form-field>  <div *ngIf="enableBreak"></div>
      </ng-container>
      <mat-checkbox *ngIf="enableOther" [(ngModel)]="otherSelected" class="input-width-radio">Other
      </mat-checkbox>

    </mat-radio-group>
  
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {Http, HttpModule} from "@angular/http";

import {LoginComponent} from "./login.component";
import {AuthService} from "./auth.service";
import {LogoutComponent} from "./logout.component";
import {MaterialModule} from "../material/material.module";
import {MatTooltipModule} from "@angular/material";
import {RegisterComponent} from "./register.component";
import {ChangePwComponent} from "./changePw/changePw.component";
import {CreateUserComponent} from "./createUser/createUser.component";
import {CreateInstitutionComponent} from "./createInstitution/createInstitution.component";
import {EditUserComponent} from "./editUser/editUser.component";


@NgModule({
  imports: [
    ReactiveFormsModule,
    HttpModule,
    BrowserModule,
    MaterialModule,
    FormsModule,
    MatTooltipModule
  ],
  exports: [
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    ChangePwComponent,
    CreateUserComponent,
    CreateInstitutionComponent,
    EditUserComponent
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    ChangePwComponent,
    CreateUserComponent,
    CreateInstitutionComponent,
    EditUserComponent
  ],
  providers: [
    AuthService
  ]
})
export class AuthModule {}

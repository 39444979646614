export class PdfParameter{
  name: string = '';
  pdfParameter: pdfParameters = new pdfParameters();
}

export class PdfParameterRequest{
  dissemination: string = '';
  quality: string = '';
  name: string = '';
  versions: PdfVersion = new PdfVersion();
}

export class PdfVersion {
  version: string='';
  editor:string ='';
  date: Date= new Date();
  contributer: string='';
  comments:string='';
}

export class pdfParameters{
  quality: string = '';
  dissemination: string = '';
  versions: PdfVersion[] = [];
}

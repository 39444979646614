export class Filter{
  data: boolean = true;
  report: boolean = true;
  services: boolean = true;
  trends: boolean = true;
  platform: boolean = true;
  region: string = '';
  regions: string[] = [];
  area: string[] = [];
}

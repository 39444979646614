export class Benchlearning {
  id: string = '';
  name : string = '';
  region : string = '';
  country : string = '';
  areaSmartSpec: string[] = [];
  type: string;
  obsPolicy : boolean = false;
  obsTechnology : boolean = false;
  cusSegmainRoTarget : string[] = [];
  valuePropcurrentValueCustomer : string[] = [];
  valuePropfutureValueCustomer : string[] = [];
  valuePropgatheredData : RoDataGatheringGroup[] = [];
  valuePropofferedServiceGroup : RoOfferedServiceGroup[] = [];
  keyResmissingCompetence : boolean = false;
  keyResmissingCompetenceReason : string = '';
  keyResbudgetCovered : number[] = [];
  keyRestoolsAndProcedures : string = '';
  keyResbestPractices : string = '';
  keyRestoolsAndProceduresImprovement : string = '';
  keyParpartnersFramework : RoPartnerShip[] = [];
  keyParpartnersOperational : OperationalPartnershipGroup[] = [];
  keyParfactorsInHouse : string = '';
  cusRelimprovementCredibility : string = '';
  cusRelcustomerGrowth : number = -1;
  cusRelcustomerChange : number = -1;
  cusRelnumCustomerServed : number = 0;
  cusRelnumGeneralUsersServed: number = 0;
  cusReltoolsCustomerRel : string = '';
  cusRelbestPractices : string = '';
  premiseLocation : number = -1;
  serviceChannelsGroups : RoCustomerServiceChannelGroup[] = [];
}

export class RoDataGatheringGroup {
  items: RoGatheredDataItem[];
}
export class RoGatheredDataItem {
  datasetName = '';
  sourceOfData = '';
  updated: number | string;

}

export class RoOfferedServiceGroup {
  items: RoServiceItem[] = [];
}


export class RoServiceItem {
  datasetName: string;
  nature: string;
  dissemination: string;
  price: string;
}

export class RoPartnerShip {
  partnerName = '';
  partnerWebSite = '';
  keyBenefitsRo = '';
  keyBenefitsPartner = '';
  city = '';
  sensitive = true;
}

export class OperationalPartnershipGroup {
  partnerships: RoPartnerShip[] = [];
}

export class RoCustomerServiceChannelGroup {
  dataItems: RoCustomerServiceChannel[] = [];
}

export class RoCustomerServiceChannel {
  channel = '';
  bestChannel = '';
  roomImprovement = '';

}



import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Benchlearning} from "../benchmarking";
import {DataService} from "../../data-service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  templateUrl: './benchlearning-detail.component.html',
  styleUrls: ['./benchlearning-detail.component.css'],
})
export class BenchlearningDetailComponent implements OnInit {
  benchlearning: Benchlearning;
  channelData = false;
  gatheredDataData = false;
  providedServicesData = false;
  operationalPartnershipData = false;
  breadcrumb;

  ngOnInit(): void {
    this.breadcrumb = this.sanitizer.bypassSecurityTrustHtml('<a href="javascript:void(0)">Benchlearning</a> / ');
    this.benchlearning = JSON.parse(localStorage.getItem("element"));
   for(let i of this.benchlearning.serviceChannelsGroups){
     if(i.dataItems.length > 0){
       this.channelData = true;
       break;
     }
   }
   for(let i of this.benchlearning.valuePropgatheredData){
     if(i.items.length > 0){
       this.gatheredDataData = true;
       break;
     }
   }
   for(let i of this.benchlearning.valuePropofferedServiceGroup){
     if(i.items.length > 0){
       this.providedServicesData = true;
       break;
     }
   }
    for(let i of this.benchlearning.keyParpartnersOperational){
      if(i.partnerships.length > 0){
        this.operationalPartnershipData = true;
        break;
      }
    }
  }

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private sanitizer:DomSanitizer) {

  }


  getToStartPage(){
    this.router.navigateByUrl('/benchlearning');
  }

  getAreasSmartSpec(){
    var out = '';
    let areas = this.benchlearning.areaSmartSpec;
    for(var i = 0; i < areas.length -1 ; i++ ){
      if(areas[i].indexOf("40") > -1){
        out += 'sustainable production technics and industry 4.0, '
      }else {
        out += areas[i] + ', ';
      }
    }
    if(areas[areas.length -1].indexOf("40") > -1){
      out += 'sustainable production technics and industry 4.0'
    }else {
      out += areas[areas.length -1];
    }

    if(out.indexOf('undefind') > -1){
      return '';
    }
    return out;
  }

  getPolicyTechnology(){
    if(this.benchlearning.obsPolicy && this.benchlearning.obsTechnology){
      return 'technology and policy';
    }
    if(this.benchlearning.obsPolicy){
      return 'policy';
    }
    if(this.benchlearning.obsTechnology){
      return 'technology'
    }
  }

  getValuePropGatheredDataUpdated(val : number){
    switch(val){
      case 0:
        return 'On-Spot';
      case 1:
        return 'Quarterly';
      case 2:
        return 'Yearly';
      case 3:
        return 'Biannually';
      case 4:
        return 'Gathered only once or very rare';
    }
  }

  getCustGrowth(val: number){
    switch (val){
      case 0:
        return 'we work with a growing number of customers';
      case 1:
        return 'we work with a well-established group of customers';
      case 2:
        return 'the average number of our customers per year fluctuates';
      case 3:
        return 'the average number of our customers per year diminishess';
    }
  }

  getCustChange(val: number){
    switch (val){
      case 0:
        return 'the core pool of customers remains the same for quite a long time';
      case 1:
        return 'our customers regularly change (numerous newcomers, numerous leavers)';
    }
  }

  getPremiseLocation(val :number){
    switch (val){
      case 0:
        return 'in a single place';
      case 1:
        return 'in various locations in a single city';
      case 2:
        return 'in 2 locations across the region';
      case 3:
        return 'in at least 3 locations across the region';
    }
  }

  groupTitlesGatheredData = ['Statistics (quantitative data)',
    'Reports (qualitative data acquainted from external sources)',
    'Contact, networking, B2B data',
    'Other (information on: events, trainings, news...'];

  groupTitlesServices = [
    'Access to services available at the RO',
    'Analytical reports based upon services (statistical analyses, GIS analyses, advanced queries etc.)',
    'Qualitative reports (state of the art, trends etc.)',
    'General B2B',
    'General information',
    'General education',
    'General workshops (foresight, trouble solving, project development etc.)',
    'Services dedicated to a certain cluster / network',
    'Dissemination towards general public',
    'Policy recommendations'];

  groupHeadersPartnership: string[] =  [
    'Analytical reports based upon datasets (statistical analyses, GIS analyses, advanced queries etc.)',
    'Qualitative reports (state of the art, trends etc.)',
    'General B2B',
    'General information',
    'General education',
    'General workshops (foresight, trouble solving, project development etc.)',
    'Services dedicated to a certain cluster / network',
    'Dissemination towards general public',
    'Policy recommendations'
  ];

  groupHeadersChannel = [
    'Analytical reports based upon datasets (statistical analyses, GIS analyses, ' +
    'advanced queries etc.)',
    'Qualitative reports (state of the art, trends etc.)',
    'General B2B',
    'General information',
    'General education',
    'General workshops (foresight, trouble solving, project development etc.)',
    'Services dedicated to a certain cluster / network',
    'Dissemination towards general public',
    'Policy recommendations'
  ];

}

import {MultipleChoiceCheckboxComponent} from './multiple-choice-checkbox.component';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {MatExpansionModule} from "@angular/material";

import {CommonModule} from "@angular/common";
import {MultipleChoiceCheckboxWithoutComponent} from "./multiple-choice-checkbox-without.component";

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpModule,
   // Ng2SmartTableModule,
    MatExpansionModule,
    FormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    MultipleChoiceCheckboxComponent,
    MultipleChoiceCheckboxWithoutComponent
  ],
  declarations: [
    MultipleChoiceCheckboxComponent,
    MultipleChoiceCheckboxWithoutComponent
  ],
  entryComponents: [
  ],
  providers: [

  ]
})
export class CommonComponentsModule {}

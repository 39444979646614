import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {templateJitUrl} from "@angular/compiler";
import {error} from "util";
import {AuthService} from "../auth.service";

@Component({
  templateUrl: './changePw.component.html',
  styleUrls: ['./changePw.component.css']
})
export class ChangePwComponent implements OnInit {

  pwForm: FormGroup;
  passwordOld;
  passwordNew;
  passwordNewRep;
  resError$;
  firstname;
  lastname;
  email;
  resErrorName$;
  resp;
  resSuc$;

  ngOnInit(): void {
    this.pwForm = new FormGroup({
      'passwordOld': new FormControl(this.passwordOld),
      'passwordNew': new FormControl(this.passwordNew,[Validators.required, Validators.minLength(8)]),
      'passwordNewRep': new FormControl(this.passwordNewRep,[Validators.required, Validators.minLength(8)]),
      'firstname' : new FormControl(this.firstname),
      'lastname' : new FormControl(this.lastname),
      'email' : new FormControl(this.email)
    });
  }

  constructor(private authService: AuthService, private router: Router) {}
  onSubmit(model,isValid: boolean) {
    if(model.passwordNew != null) {
      this.passwordOld = model.passwordOld;
      this.passwordNew = model.passwordNew;
      this.passwordNewRep = model.passwordNewRep;
      this.authService.changePW(this.passwordOld, this.passwordNew, this.passwordNewRep)
        .then(res => {
          if (res !== 'Password successfully changed') {
            this.resError$ = res;
            this.resp = "";
            throw res;
          }else {
            this.resError$ = "";
            this.resp = res;
          }
        })
    }
    if(model.firstname !== null || model.lastname !== null || model.email !== null){
      this.firstname = model.firstname;
      this.lastname = model.lastname;
      this.email = model.email;
      this.authService.changeNameMail(this.firstname,this.lastname,this.email).then(
        res => {
          if(res !== 'User details successfully updated'){
            this.resErrorName$ = res;
            this.resSuc$="";
            throw res;
          }else {
            this.resSuc$ = res;
            this.resErrorName$ = "";
          }
        }
      )
    }
  }
}

import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {IndexComponent} from "./index/index.component";
import {BenchmarkingComponent} from "./benchmarking/benchmarking.component";
import {AuthGuard} from "./auth/authGuard";
import {LoginComponent} from "./auth/login.component";
import {LogoutComponent} from "./auth/logout.component";
import {BenchlearningDetailComponent} from "./benchmarking/detail/benchlearning-detail.component";
import {PrintBenchlearningPdfComponent} from "./benchmarking/printPdf/printPdf.component";
import {CompareOverviewComponent} from "./benchmarking/compare/compare_overview.component";
import {RegionSectorComponent} from "./framework/region_sector/region_sectors.component";
import {RegionalEconomyComponent} from "./framework/regional_economy/regional_economy.component";
import {InnovationComponent} from "./framework/innovation/innovation.component";
import {CompetitivenessComponent} from "./framework/competitiveness/competitiveness.component";
import {DemandComponent} from "./demand-overlay/demand.component";
import {SwOverlayComponent} from "./overlay/swOverlay.component";
import {RegisterComponent} from "./auth/register.component";
import {CreateUserComponent} from "./auth/createUser/createUser.component";
import {CreateInstitutionComponent} from "./auth/createInstitution/createInstitution.component";
import {ChangePwComponent} from "./auth/changePw/changePw.component";
import {EditUserComponent} from "./auth/editUser/editUser.component";

const routes: Routes = [
  { path: '', component: IndexComponent},
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'logout', component: LogoutComponent},
  { path: 'benchlearning', component: BenchmarkingComponent, canActivate:[AuthGuard]},
  { path: 'regionSector', component: RegionSectorComponent, canActivate:[AuthGuard]},
  { path: 'innovation', component: InnovationComponent, canActivate:[AuthGuard]},
  { path: 'competitiveness', component: CompetitivenessComponent, canActivate:[AuthGuard]},
  { path: 'regionalEconomy', component: RegionalEconomyComponent, canActivate:[AuthGuard]},
  { path: 'benchlearning/detail', component: BenchlearningDetailComponent, canActivate:[AuthGuard]},
  {path: 'benchlearning/pdf/:id', component: PrintBenchlearningPdfComponent, canActivate:[AuthGuard]},
  { path: 'benchlearning/compare', component: CompareOverviewComponent, canActivate:[AuthGuard]},
  { path: 'demand', component: DemandComponent, canActivate:[AuthGuard]},
  { path: 'createUser', component: CreateUserComponent, canActivate:[AuthGuard]},
  {path: 'changePassword', component: ChangePwComponent,canActivate:[AuthGuard]},
  { path: 'updateUser/:id', component: EditUserComponent, canActivate:[AuthGuard]},
  { path: 'createInstitution', component: CreateInstitutionComponent, canActivate:[AuthGuard]},
  { path: 'updateInstitution/:id', component: CreateInstitutionComponent, canActivate:[AuthGuard]},
  { path: 'overlay', component: SwOverlayComponent, canActivate:[AuthGuard]}


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: [],
  providers: []
})
export class AppRoutingModule {}

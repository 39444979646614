import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MatRadioGroup, MatSelect} from "@angular/material";
import {DataService} from "../../data-service";
import {AuthService} from "../auth.service";

@Component({
  templateUrl: './createUser.component.html',
  styleUrls: ['./createUser.component.css']
})
export class CreateUserComponent implements OnInit {

  userForm: FormGroup;
  name;
  password;
  role;
  firstname;
  lastname;
  mail;
  institutionID;
  resError$;

  institutions = [];

  ngOnInit(): void {
    this.dataService.getInsitution().subscribe(r => {
      this.institutions = r;
    });
    this.userForm = new FormGroup({
      'name': new FormControl(this.name,[Validators.required, Validators.minLength(5),Validators.pattern('^[^.]*$')]),
      'password': new FormControl(this.password,[Validators.required, Validators.minLength(8)]),
      'role': new FormControl(this.role),
      'email': new FormControl(this.mail),
      'firstname' : new FormControl(this.firstname),
      'lastname': new FormControl(this.lastname),
      'institutionID': new FormControl(this.institutionID),
    });
  }

  constructor(public authService: AuthService, private router: Router, private dataService: DataService) {}

  onSubmit(model,isValid: boolean) {
    this.name = model.name;
    this.password = model.password;
    this.role = model.role;
    this.institutionID = model.institutionID;
    this.firstname = model.firstname;
    this.lastname = model.lastname;
    this.mail = model.email;
    this.authService.createUser(this.name, this.password, this.role, this.institutionID, this.firstname, this.lastname, this.mail)
      .then(res => {
        if(res) {
          this.resError$ = res;
        }
    });
  }
}

<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">Register</h1>
    </div>
  </div>
</header>
<mat-card class="mat-card-align">
  <div>{{resError$}}</div>


  <mat-form-field class="input-width">
    <textarea matInput id="name" [(ngModel)]="registration.name" placeholder="Name" class="input-width"></textarea>
  </mat-form-field><br>

  <mat-form-field class="input-width">
    <textarea matInput id="institution" [(ngModel)]="registration.institution" placeholder="Institution" class="input-width"></textarea>
  </mat-form-field><br>

  <mat-form-field class="input-width">
    <textarea matInput id="institutionWebsite" [(ngModel)]="registration.institutionWebsite" placeholder="Institution Website" class="input-width"></textarea>
  </mat-form-field><br>

  <mat-form-field class="input-width">
    <textarea matInput id="email" [(ngModel)]="registration.email" placeholder="Email" class="input-width"></textarea>
  </mat-form-field><br>

  <mat-form-field class="input-width">
    <mat-select (closed)="setRegions()" [(ngModel)]="registration.country" placeholder="Country"  id="country">
      <mat-option value="Austria">Austria</mat-option>
      <mat-option value="Czech Republic">Czech Republic</mat-option>
      <mat-option value="Germany">Germany</mat-option>
      <mat-option value="Hungary">Hungary</mat-option>
      <mat-option value="Italy">Italy</mat-option>
      <mat-option value="Poland">Poland</mat-option>
      <mat-option value="Slovenia">Slovenia</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field class="input-width" >
    <mat-select placeholder="Region" [(ngModel)]="registration.region" id="region" [disabled]="selectDisabled">
      <mat-option *ngFor="let region of regionSelected.value" [value]="region.name">
        {{region.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>

  <mat-card-actions class="input-width">
    <button class="btn-align" mat-fab type="submit" (click)="onSubmit()"><i class="material-icons">done</i></button>
  </mat-card-actions><br>

</mat-card>

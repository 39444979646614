
import {Component, OnInit} from "@angular/core";
import {Chart} from "angular-highcharts";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material";
import {DataService} from "../../data-service";
import {RegionalEconomyData} from "./regionalEconomy";

@Component({
  templateUrl: './regional_economy.component.html',
  styleUrls: ['../framework.css'],
})
export class RegionalEconomyComponent implements OnInit {
// data from the excel which was parsed via vba
  selectedIndicator = 'Population (in mln.)';
  indicators = ['Population (in mln.)','Regional GDP (nominal) at current market prices','Regional GDP per capita at current market prices - Euro per inhabitant (EUR_HAB)'
    ,'Regional GDP per capita Purchasing power standard (PPS) per inhabitant (PPS_HAB)','Regional GDP per capita Purchasing power standard (PPS) per inhabitant in percentage of the EU average (PPS_HAB_EU)',
    '% of National GDP','% of unemployment','N. of local units by NACE rev 2**(dal 2008) – (V11210)',
  'Number of persons employed by NACE rev 2** (V16110)', 'Share of persons employed in manufacturing by NACE rev 2** on total employed', '% of SMEs',
  'Trade Openness Index (Exports + Imports)/(Gross Domestic Product)'];
  regionalEconomyData;
  showDropDown = true;
  region = '';
  showAllTable = true;
  showDetailTable = false;
  position = 0;


  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.dataService.getStatisticDataRegionalEconomy().subscribe(res => {
      this.regionalEconomyData = res;
      for(let e of this.regionalEconomyData){
        let tmp = e.region;
        this.chart.addSeries({type: 'column', name: e.region, data: [{name: '2005', y: e.data[0].year2005},
          {name: '2008', y: e.data[0].year2008},{name: '2011', y: e.data[0].year2011},{name: '2014', y: e.data[0].year2014},
          {name: '2017', y: e.data[0].year2017}],
          events: {
            click: (e) => {
              this.region = tmp;
              this.drawDiagramForIndicator();
            }
          }}, true, false);
      }
    })
  }

  showDiagramForEconomyStatistic(){
    this.showAllTable = true;
    this.showDetailTable = false;
    if(this.region === '') {
      this.initializeChart()

      let pos = this.getNumberOfRegEconomy(this.selectedIndicator);
      this.position = pos;
      for (let e of this.regionalEconomyData) {
        let tmp = e.region;
        this.chart.addSeries({
          type: 'column',
          name: e.region, data: [{name: '2005', y: e.data[pos].year2005},
            {name: '2008', y: e.data[pos].year2008}, {name: '2011', y: e.data[pos].year2011}, {
              name: '2014',
              y: e.data[pos].year2014
            },
            {name: '2017', y: e.data[pos].year2017}],
          events: {
            click: (e) => {
              this.region = tmp;
              this.drawDiagramForIndicator();
            }
          }
        }, true, false);
      }
    }else{
      this.drawDiagramForIndicator();
    }
  }

  regionalEconomy = new RegionalEconomyData();
  drawDiagramForIndicator(){
    this.showAllTable = false;
    this.showDetailTable = true;
    this.initializeChartLine();
    //this.chart.options.title.text = 'Regional economy statistic data - '  + this.selectedIndicator
    let index = this.getNumberOfRegEconomy(this.selectedIndicator);
    for (let c of this.regionalEconomyData) {
      if (c.region === this.region) {
        this.regionalEconomy.year2017 = c.data[index].year2017;
        this.regionalEconomy.year2014 = c.data[index].year2014;
        this.regionalEconomy.year2011 = c.data[index].year2011;
        this.regionalEconomy.year2008 = c.data[index].year2008;
        this.regionalEconomy.year2005 = c.data[index].year2005;

        this.chart.addSeries({
          type: 'column',
          name: this.selectedIndicator,
          data: [{name: '2005', y: c.data[index].year2005},{name: '2008', y: c.data[index].year2008}, {name: '2011', y: c.data[index].year2011},{name: '2014', y: c.data[index].year2014}, {
            name: '2017', y: c.data[index].year2017}]
        }, true, false)
      }
    }
  }

  getNumberOfRegEconomy(indicator): number{
    switch (indicator) {
      case 'Population (in mln.)':
        return 0;
      case 'Regional GDP (nominal) at current market prices':
        return 1;
      case 'Regional GDP per capita at current market prices - Euro per inhabitant (EUR_HAB)':
        return 2;
      case 'Regional GDP per capita Purchasing power standard (PPS) per inhabitant (PPS_HAB)':
        return 3;
      case 'Regional GDP per capita Purchasing power standard (PPS) per inhabitant in percentage of the EU average (PPS_HAB_EU)':
        return 4;
      case '% of National GDP':
        return 5;
      case '% of unemployment':
        return 6;
      case 'N. of local units by NACE rev 2**(dal 2008) – (V11210)':
        return 7;
      case 'Number of persons employed by NACE rev 2** (V16110)':
        return 8;
      case 'Share of persons employed in manufacturing by NACE rev 2** on total employed':
        return 9;
      case '% of SMEs':
        return 10;
      case 'Trade Openness Index (Exports + Imports)/(Gross Domestic Product)':
        return 11;
      default:
        return 0;
    }
  }

  getNameOfRegEconomy(indicator): string{
    switch (indicator) {
      case 0:
        return 'Population (in mln.)';
      case 1:
        return 'Regional GDP (nominal) at current market prices';
      case 2:
        return 'Regional GDP per capita at current market prices - Euro per inhabitant (EUR_HAB)';
      case 3:
        return 'Regional GDP per capita Purchasing power standard (PPS) per inhabitant (PPS_HAB)';
      case 4:
        return  '% of National GDP'
      case 5:
        return '% of unemployment (2016)';
      case 6:
        return  'N. of local units by NACE rev 2**(dal 2008) – (V11210)';
      case 7:
        return 'Number of persons employed by NACE rev 2** (V16110)';
      case 8:
        return 'Share of persons employed in manufacturing by NACE rev 2** on total employed';
      case 9:
        return '% of SMEs';
      case 10:
        return 'Trade Openness Index (Exports + Imports)/(Gross Domestic Product)';
      default:
        return '';
    }
  }


  //thir zoom level shows a line chart instead of a bar chart
  initializeChartLine(){
    this.chart = new Chart( {chart: {
      type: 'line'
    },title: {
      text: 'Regional economy statistic data'
    },
      xAxis: {
        title: {
          text: ''
        },
        labels:
          {
            enabled: true
          },
        categories: ['2005','2008','2011','2014','2017']
      },
      yAxis: {
        title: {
          text: 'Value'
        }
      },
      credits: {
        enabled: true
      }
    });

  }


  initializeChart(){
    this.chart = new Chart( {chart: {
      type: 'column'
    },title: {
      text: 'Regional economy statistic data'
    },
      xAxis: {
        title: {
          text: ''
        },
        labels:
          {
            enabled: true
          },
        categories: ['2005','2008','2011','2014','2017']
      },
      yAxis: {
        title: {
          text: 'Value'
        }
      },
      credits: {
        enabled: true
      }
    });

  }

  chart = new Chart( {chart: {
    type: 'column'
  },title: {
    text: 'Regional economy statistic data'
  },
    xAxis: {
      title: {
        text: ''
      },
      labels:
        {
          enabled: true
        },
      categories: ['2005','2008','2011','2014','2017']
    },
    yAxis: {
      title: {
        text: 'Value'
      }
    },
    credits: {
      enabled: true
    }
  });


}

import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {CdkScrollable} from "@angular/cdk/scrolling";
@Component({
  selector: 'sw-popup-info',
  styles: [`
    a {
      color: black;
      text-decoration: underline;
    }
  `],
  template: `
    <mat-card>
      <h4>Information about the Overlay Layer</h4>
      <p>The demand overlayer shows data on the current needs and future datasets and services requested by SMEs. 
        The diagrams can be filtered by the different categories, regions and areas of smart specialisation.
      </p>
    </mat-card>

  `
})
export class PopupInfoComponent implements OnInit{
  name;
  @ViewChild(CdkScrollable) scrollable: CdkScrollable;

  ngOnInit(): void {


  }



}

import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import * as FileSaver from "file-saver";
import {DataService} from "../../data-service";
import {PdfParameter, PdfParameterRequest, PdfVersion} from "./pdfParameter";


@Component({
  templateUrl: './printPdf.component.html',
  styleUrls: ['./printPdf.component.css']
})
export class PrintBenchlearningPdfComponent implements OnInit {
  resErrorName$;
  pdfParameterRequest = new PdfParameterRequest();

  ngOnInit(): void {
    let id = this.router.url.split('/')[3];
    if (id) {
      this.dataService.getPdfData(id).subscribe(
        res => {
          this.pdfParameterRequest.name = res.name;
          this.pdfParameterRequest.dissemination = res.pdfParameter.dissemination;
          this.pdfParameterRequest.quality = res.pdfParameter.quality;
          this.pdfParameterRequest.versions = res.pdfParameter.versions[res.pdfParameter.versions.length-1];
        },
        error2 => {
          console.log(error2.error)
        }
      );
    }

  }

  constructor(private dataService: DataService, private router: Router) {
  }

  onSubmit() {
    let id = this.router.url.split('/')[3];

    if (id) {
      this.pdfParameterRequest.versions.date = new Date();
      this.dataService.putBenchlearningReportParameter(id, this.pdfParameterRequest).subscribe(
        res => {
          this.dataService.printBenchlearningReport(id).subscribe(
            res => {
              var filename = 'Benchlearning_'+this.getCurrentDate()
              var mediaType = 'application/pdf';
              var blob = new Blob([res], {type: mediaType});
              FileSaver.saveAs(blob, filename);
              this.router.navigate(['/benchlearning']);
            }
          );
        }
      );
    }
  }

  getCurrentDate(): string {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }
}

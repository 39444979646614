import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Util} from "../benchmarking/util";

@Component({
  selector: 'sw-multiple-choice-checkbox',
  template: `
    <div *ngIf="enableBreak"></div>


    <mat-radio-group>
      <ng-container *ngFor="let opt of _options; let i = index">
        <mat-checkbox *ngIf="!enableBreak && i === 0"  [(ngModel)]="opt.selected" (ngModelChange)="selectionChanged()"  style="margin-left : 250px" class="input-width-radio"
                      [value]="opt.value">{{opt.displayValue}}
        </mat-checkbox>
        <mat-checkbox *ngIf="!enableBreak && i !== 0" [(ngModel)]="opt.selected" (ngModelChange)="selectionChanged()"  style="margin-left: 10px" class="input-width-radio"
                      [value]="opt.value">{{opt.displayValue}}
        </mat-checkbox>
        <mat-checkbox *ngIf="enableBreak" [(ngModel)]="opt.selected" (ngModelChange)="selectionChanged()"  style="margin-left: 250px" class="input-width-radio"
                      [value]="opt.value">{{opt.displayValue}}
        </mat-checkbox>

        <mat-form-field class="input-width"  *ngIf="comments && opt.selected" >
    <textarea matInput  (blur)="commentTextChanged()" [(ngModel)]="_dictSelected[opt.value]"
              placeholder="Please specify:"></textarea>
        </mat-form-field>  <div *ngIf="enableBreak"></div>
      </ng-container>
      <mat-checkbox *ngIf="enableOther" [(ngModel)]="otherSelected" class="input-width-radio">Other
      </mat-checkbox>

    </mat-radio-group>
  `
})
export class MultipleChoiceCheckboxComponent {
  _options: CheckBoxOptionState[];
  _dictSelected = {};

  _other: string;
  @Output()
  selectedChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  set other(value: string) {
    this.otherSelected = value != null && value.length > 0;
    this._other = value;
  }

  get other(): string {
    return this._other;
  }

  @Output()
  otherChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  commentChange: EventEmitter<{ [id: string]: string }> = new EventEmitter<{ [id: string]: string }>();

  otherSelected: boolean;

  @Input()
  enableOther = false;

  @Input()
  enableBreak = false;

  @Input()
  comments = false;


  get options(): CheckboxOption[] {
    return this._options;
  }

  @Input()
  set options(opts: CheckboxOption[]) {
    this._options = opts.map(o => CheckBoxOptionState.fromCheckboxOption(o));
  }

  @Input()
  set optionTexts(options: string[]) {
    this.options = options.map(o => new CheckboxOption(o, o));
  }

  @Input()
  set dictSelected(values: { [id: string]: string }) {
    this._dictSelected = values;
  }

  get selected(): string[] {
    return this._options.filter(o => o.selected).map(o => o.value);
  }

  @Input()
  set selected(values: string[]) {
    const oldSelected = this.selected;

    if (values == null) {
      console.log('selected values null');
      return;
    }

    if (Util.arrayEquals(values, oldSelected)) {
      return;
    }

    this._options.forEach(o => o.selected = false);
    values.forEach(cur => {
      const opt = this._options.find(o => o.value === cur);
      if (opt != null) {
        opt.selected = true;
      }
    });

    const newSelected = this.selected;

    if (!Util.arrayEquals(oldSelected, newSelected)) {
      this.selectedChange.emit(this.selected);
    }
  }

  @Input()
  set selectedDict(values: {[id:string]:string}) {
    const oldSelected = this.selected;

    if (values == null) {
      console.log('selected values null');
      return;
    }

    if (Util.arrayEquals(Object.keys(values), oldSelected)) {
      return;
    }

    this._options.forEach(o => o.selected = false);
    Object.keys(values).forEach(cur => {
      const opt = this._options.find(o => o.value === cur);
      if (opt != null) {
        opt.selected = true;
      }
    });

    const newSelected = this.selected;

    if (!Util.arrayEquals(oldSelected, newSelected)) {
      this.selectedChange.emit(this.selected);
    }
  }

  otherTextChanged() {
    if (!this.otherSelected) {
      this.other = '';
    }
    this.otherChange.emit(this.other);

  }

  selectionChanged() {
    this.selectedChange.emit(this.selected);
  }

  commentTextChanged() {
    this.commentChange.emit(this._dictSelected);
  }
}

export class CheckboxOption {
  constructor(public displayValue: string = '', public value: string = '') {
  }

  static fromChoices(...values: string[]): CheckboxOption[] {
    return values.map(v => new CheckboxOption(v, v));
  }

  static fromDictChoices(...values: string[]): CheckboxOption[] {
    return values.map(v => {
      if (v.indexOf('4.0') > -1) {
        return new CheckboxOption(v, 'sustainable production technics and industry 40');
      } else {
        return new CheckboxOption(v, v)
      }
    });
  }

  static fromDifChoices(...values: string[]): CheckboxOption[] {
    return values.map(v => {
      if (v.indexOf('project-based') > -1) {
        return new CheckboxOption('project based',v);
      }else if(v.indexOf('selfstanding')> -1) {
        return new CheckboxOption('selfstanding',v);
      }else if(v.indexOf('publicly offered')> -1) {
        return new CheckboxOption('publicly offered',v);
      } else if(v.indexOf('tailormade')> -1) {
        return new CheckboxOption('tailormade to the order',v);
      } else if(v.indexOf('paid')> -1) {
        return new CheckboxOption('paid',v);
      } else if(v.indexOf('free')> -1) {
        return new CheckboxOption('free of charge',v);
      }else {
        return new CheckboxOption(v, v)
      }
    });
  }

  private static generateCheckBoxValue(displayValue: string, normalize: boolean) {
  }
}

class CheckBoxOptionState extends CheckboxOption {
  selected = false;

  static fromCheckboxOption(opt: CheckboxOption): CheckBoxOptionState {
    return {
      displayValue: opt.displayValue,
      value: opt.value,
      selected: false
    };
  }
}

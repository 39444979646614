
import {DataService} from "../data-service";

declare var require: any;

import {Component, OnInit, Pipe} from '@angular/core';

import * as Highcharts from 'highcharts/highstock';
import {ActivatedRoute} from "@angular/router";
import {SimilarityDemandResponse} from "./similarityDemandResponse";
import {Chart, MapChart} from "angular-highcharts";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {Popup_infoComponent} from "./popup_info.component";
import {DomSanitizer} from '@angular/platform-browser';
import {ObsSimilarityDemandResponse, ObsSimilarityResponse} from "./obsSimilariryDemandResponse";


const HC_exporting = require('highcharts/modules/exporting');



HC_exporting(Highcharts);

Highcharts.setOptions({
  title: {
    style: {
      color: 'orange'
    }
  }
});

@Component({
  selector: 'sw-demand',
  templateUrl: 'demand.component.html',
  styleUrls: ['demand.component.css']
})

export class DemandComponent implements OnInit {
//compares audit with D.T2.1.1
  textSelection;
  countryDemand;
  countryAudit;
  similarityRes: SimilarityDemandResponse[] = [];
  breadcrumb1;
  breadcrumVisible1 = false;
  breadcrumb2;
  breadcrumVisible2 = false;
  regionNotClick = false;
  region = '';
  showAllTable = false;
  showAllObsTable = true;
  showRegionTable = false;
  showObsTable = false;
  showAllRegionObsTable = false;
  showAllRegionTable = false;
  posRegion = 0;
  selection = ['observatory','region'];
  selectionObs = ['own region','all regions'];
  selectedObs = 'own region'
  selected = 'observatory';
  showSelection = false;
  showObsRegionTable = false;
  obsSimilarityRes: ObsSimilarityDemandResponse;
  obsNotClick = false;
  obsBreadcrumb = '';
  showSelectionRegion = false;
  selectionRegion = ['own observatories','all observatories']
  selectedRegion = 'own observatories'
  regionAllNotClick = false;
  regionAll = '';
  selectedToggle = false;


  constructor(private dataService: DataService, private route: ActivatedRoute,private dialog: MatDialog, private sanitizer:DomSanitizer) {

  }

  tmpList = [];

  ngOnInit(): void {
    this.countryDemand = '';
    this.countryAudit = '';
    this.textSelection = 'You can select a region/country to get more details about the supply and demand within the selected region/country';
    this.dataService.getDataDemandAuditData().subscribe(res => {
      this.similarityRes = res;
      for(let e of this.similarityRes){
        for(let o of e.similarityROs ) {
          this.tmpList.push(o);
        }
      }
      this.setDataAllObs();
    })

  }

  chooseDiagram(){
    if(this.selected === 'observatory'){
      this.setDataAllObs();
    }else{
      this.setData();
    }
  }

  chooseDiagramObs(){
    if(this.selectedObs === 'own region'){
      this.selectObservatory(this.curObservatory)
    }else{
      this.obsNotClick = false;
      this.selectObsRegion(this.curObservatory)
    }
  }


  chooseDiagramRegion(){
    if(this.selectedRegion === 'own observatories'){
      this.selectRegion(this.region)
    }else{
      this.selectRegionAll(this.region)
    }
  }

  checkButtonToggle(){
    if(this.selectedToggle === false){
      this.selectedToggle = true;
      this.selectRegionCategory(this.region)
    }else{
      this.selectedToggle = false;
      this.selectRegion(this.region)
    }
  }


  setDataAllObs(){
    this.showAllObsTable = true;
    this.showAllTable = false;
    this.showObsTable = false;
    this.showAllRegionObsTable = false;
    this.showRegionTable = false;
    this.breadcrumVisible1 = false;
    this.regionNotClick = false;
    this.breadcrumVisible2 = false;
    this.showObsRegionTable = false;
    this.showSelection = false;
    this.breadcrumb3Visible = false;
    this.obsNotClick = false;
    this.regionAllNotClick = false;
    this.showSelectionRegion = false;
    this.showAllRegionTable = false;
    this.initializeChart('Comparison of supply and demand', 'Observatory Name');

    this.textSelection = 'You can select an Observatory';
//    this.chart.options.xAxis[0].title.text = 'Observatories';

    this.chart.removeSeries(0);
    for(let e of this.similarityRes){
      for(let o of e.similarityROs ){
        this.chart.addSeries({
          name: o.obsName,
          type: 'column',
          data: [+(o.results[6].toFixed(3))],
          color: this.getColorCountry(e.country),
          events: {
            click: () => {
              this.selectObservatory(o)
              this.region = e.region;
              this.breadcrumVisible1 = true;
              this.breadcrumb3Visible = false;
              this.breadcrumb3 = '';
              this.breadcrumb1 = this.sanitizer.bypassSecurityTrustHtml('<a href="javascript:void(0)">all</a> / ');
              this.breadcrumVisible2 = true;
              this.breadcrumb2 = ('<a href="javascript:void(0)">'+ e.region + '</a> ')
            }
          }
        }, true, false);
      }
    }
  }


  setData() {
    this.selected = 'region'
    this.showAllObsTable = false;
    this.showAllTable = true;
    this.showObsTable = false;
    this.showRegionTable = false;
    this.showAllRegionObsTable = false;
    this.breadcrumVisible1 = false;
    this.breadcrumVisible2 = false;
    this.regionNotClick = false;
    this.showObsRegionTable = false;
    this.showSelection = false;
    this.breadcrumb3Visible = false;
    this.obsNotClick = false;
    this.showSelectionRegion = false;
    this.showAllRegionTable = false;
    this.regionAllNotClick = false;

    this.initializeChart('Comparison of supply and demand', 'Region / Country');

    this.textSelection = 'You can select a region/country to get more details about the supply and demand within the selected region/country';

    this.chart.removeSeries(0);

    for (let e of this.similarityRes) {
      if(!isNaN(e.averageRes)) {
        this.chart.addSeries({
          name: e.region,
          type: 'column',
          data: [+(e.averageRes.toFixed(3))],
          color: this.getColorCountry(e.country),
          events: {
            click: () => {
              this.selectRegion(e.region)
              this.breadcrumVisible1 = true;
              this.regionNotClick = true;
              this.region = e.region;
              this.posRegion = this.getPosition(e.region);
              this.breadcrumb1 = this.sanitizer.bypassSecurityTrustHtml('<a href="javascript:void(0)">all</a> / ');
            }
          }
        }, true, false);
      }
    }
  }

  getPosition(region: string) : number{
    for(var i = 0; i < this.similarityRes.length; i++){
      if(region === this.similarityRes[i].region){
        return i;
      }
    }
  }

  selectedRegionsData = new SimilarityDemandResponse();
  selectRegionAll(region){
    this.showAllObsTable = false;
    this.regionNotClick = false;
    this.breadcrumVisible2 = true;
    this.showAllRegionObsTable = false;
    this.showRegionTable = false;
    this.showAllTable = false;
    this.showObsTable = false;
    this.showObsRegionTable = false;
    this.showSelection = false;
    this.breadcrumb3Visible = false;
    this.obsNotClick = false;
    this.showSelectionRegion = true;
    this.showAllRegionTable = true;
    this.regionAllNotClick = true;
    this.regionAll = ' / compare with all observatories'
    this.breadcrumb2 = ('<a href="javascript:void(0)">'+ region + '</a> ')


    this.initializeChart('Comparison of supply and demand', 'Observatories');

    this.dataService.getDataDemandRegionAllObservatories(region).subscribe(res => {
      this.selectedRegionsData = res;
      for(let o of this.selectedRegionsData.similarityROs ) {
        this.chart.addSeries({
          type: 'column',
          name: o.obsName,
          data: [+(o.results[6].toFixed(3))],
          events: {
            click: () => {
              this.selectObservatory(o,o.region)
            }
          }
        }, true, false)
      }
    })
  }

  selectRegion(regionName) {
    this.selectedRegion = 'own observatories'
    this.showAllObsTable = false;
    this.regionNotClick = true;
    this.breadcrumVisible2 = false;
    this.showAllRegionObsTable = false;
    this.showRegionTable = true;
    this.showAllTable = false;
    this.showObsTable = false;
    this.showObsRegionTable = false;
    this.showSelection = false;
    this.breadcrumb3Visible = false;
    this.obsNotClick = false;
    this.showSelectionRegion = true;
    this.showAllRegionTable = false;
    this.regionAllNotClick = false;


    var result: SimilarityDemandResponse[] = [];

    for (let e of this.similarityRes) {
      if (e.region === regionName) {
        result.push(e);
      }
    }
    if (result.length > 1) {


    } else if (result.length === 1) {
      this.region = regionName;
      if(regionName === 'Hungary' || regionName === 'Slovenia'){
        this.textSelection = 'Details of all ROs in the country ' + result[0].region;
      }else {
        this.textSelection = 'Details of all ROs in the region ' + result[0].region;
      }
      this.initializeChart('Compare all ROs with the demand of their region', 'Observatories' );
      for (let o of result[0].similarityROs) {
        this.chart.addSeries({
          name: o.obsName, type: 'column', data: [+(o.results[6].toFixed(3))], events: {
            click: () => {
              this.breadcrumVisible2 = true;
              this.breadcrumb2 = ('<a href="javascript:void(0)">'+ regionName + '</a>')
              this.selectObservatory(o)
            }
          }
        }, true, false)
      }
    }
  }


  selectRegionCategory(regionName) {
    this.selectedRegion = 'own observatories'
    this.showAllObsTable = false;
    this.regionNotClick = true;
    this.breadcrumVisible2 = false;
    this.showAllRegionObsTable = false;
    this.showRegionTable = true;
    this.showAllTable = false;
    this.showObsTable = false;
    this.showObsRegionTable = false;
    this.showSelection = false;
    this.breadcrumb3Visible = false;
    this.obsNotClick = false;
    this.showSelectionRegion = true;
    this.showAllRegionTable = false;
    this.regionAllNotClick = false;


    var result: SimilarityDemandResponse[] = [];

    for (let e of this.similarityRes) {
      if (e.region === regionName) {
        result.push(e);
      }
    }
    if (result.length > 1) {


    } else if (result.length === 1) {
      this.region = regionName;
      if(regionName === 'Hungary' || regionName === 'Slovenia'){
        this.textSelection = 'Details of all ROs in the country ' + result[0].region;
      }else {
        this.textSelection = 'Details of all ROs in the region ' + result[0].region;
      }
      this.initializeChart('Compare all ROs with the demand of their region', 'Observatories' );
      var avgRoValues = 0;
      var avgRoDataset = 0;
      var avgRoService = 0;
      var avgRoChannel = 0;
      var avgPayService = 0;
      var avgCooperations = 0;
      var counter = 0;
      for (let o of result[0].similarityROs) {
          avgRoValues = avgRoValues + o.results[0];
          avgRoDataset = avgRoDataset + o.results[1];
          avgRoService = avgRoService + o.results[2];
          avgRoChannel = avgRoChannel + o.results[3];
          avgPayService = avgPayService + o.results[4];
          avgCooperations = avgCooperations + o.results[5];
          counter++;
          if(counter === result[0].similarityROs.length){
            this.chart.addSeries({name: "RO values",          type: 'column',
              data: [+(avgRoValues/counter).toFixed(3)]}, true, false);
            this.chart.addSeries({name: "RO datasets",          type: 'column',
              data: [+(avgRoDataset/counter).toFixed(3)]}, true, false);
            this.chart.addSeries({name: "RO services",          type: 'column',
              data: [+(avgRoService/counter).toFixed(3)]}, true, false);
            this.chart.addSeries({name: "RO channels",          type: 'column',
              data: [+(avgRoChannel/counter).toFixed(3)]}, true ,false);
            this.chart.addSeries({name: "Pay services",          type: 'column',
              data: [+(avgPayService/counter).toFixed(3)]}, true, false);
            this.chart.addSeries({name: "Cooperations",           type: 'column',
              data: [+(avgCooperations/counter).toFixed(3)]}, true, false);
          }
      }
    }
  }
  openDialogInfo() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    this.dialog.open(Popup_infoComponent, dialogConfig);

  }

  curObservatory;
  selectObservatory(observatory, region);
  selectObservatory(observatory);
  selectObservatory(observatory,region?) {
    this.selectedObs = 'own region'
    this.showAllObsTable = false;
    this.regionNotClick = false;
    this.showObsTable = true;
    this.showAllTable = false;
    this.showRegionTable = false;
    this.showSelection = true;
    this.showAllRegionObsTable = false;
    this.showObsRegionTable = false;
    this.breadcrumb3Visible = false;
    this.breadcrumb3 = ''
    this.showSelectionRegion = false;
    this.showAllRegionTable = false;
    this.regionAllNotClick = false;

    this.obsNotClick = true;
    this.obsBreadcrumb =' / '+ observatory.obsName;

    this.initializeChart('Comparison of supply and demand', 'Audit sections');

    this.curObservatory = observatory;
    if(region){
      this.showSelection = false;
      this.textSelection = 'Details of the observatory '+ observatory.obsName + ' compared with the region ' + this.region;
    }else{
      this.textSelection = 'Details of the observatory ' + observatory.obsName;

    }

/*TODO    this.chart.options.yAxis[0].plotLines = [{
      label: {text: 'Average'},
      color: 'red',
      dashStyle: 'shortdot',
      value: +observatory.results[6].toFixed(3),
      width: 2
    }]*/

    this.chart.addSeries({name: "RO values",          type: 'column',
      data: [+observatory.results[0].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "RO datasets",          type: 'column',
      data: [+observatory.results[1].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "RO services",          type: 'column',
      data: [+observatory.results[2].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "RO channels",          type: 'column',
      data: [+observatory.results[3].toFixed(3)]}, true ,false);
    this.chart.addSeries({name: "Pay services",          type: 'column',
      data: [+observatory.results[4].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "Cooperations",           type: 'column',
      data: [+observatory.results[5].toFixed(3)]}, true, false);

  }



  tmpListRegion = [];
  breadcrumb3Visible = false;
  breadcrumb3 = '';
  selectObsRegion(observatory){
    this.breadcrumb3Visible = true;
    this.obsNotClick = false;
    this.obsBreadcrumb = ''
    this.breadcrumb3 = ('<a href="javascript:void(0)">/ '+observatory.obsName +'</a> / compare with all regions')

    this.tmpListRegion = []
    this.textSelection = 'Compare the observatory with the demand of all regions';
    this.showSelection = true;

    this.showAllRegionObsTable = true;
    this.showObsRegionTable = false;
    this.showAllRegionTable = false;
    this.regionAllNotClick = false;

    this.dataService.getDataDemandObservatory(observatory.id).subscribe(
      res=>{
        this.obsSimilarityRes = res;
        this.showAllObsTable = false;
        this.regionNotClick = false;
        this.showObsTable = false;
        this.showAllTable = false;
        this.showRegionTable = false;
        this.showSelectionRegion = false;

        this.initializeChart('Comparison of supply and demand', 'Regions');
    /*TODO average line not working
        this.chart.options.yAxis[0].plotLines = [{
          label: {text: 'Average'},
          color: 'red',
          dashStyle: 'shortdot',
          value: this.obsSimilarityRes .avgSimilarity.toFixed(3),
          width: 2
        }]*/

        for (let e of this.obsSimilarityRes.responses) {
          this.tmpListRegion.push(e);
          if(!isNaN(this.obsSimilarityRes.avgSimilarity)) {
            this.chart.addSeries({
              name: e.region,
              type: 'column',
              data: [+(e.resultsPos[6].toFixed(3))],
              color: this.getColorCountry(e.country),
              events: {
                click: () => {
                    this.getObsForRegion(e.region)
                }
              }
            }, true, false);
          }
        }
      }
    )
  }

  curRegionObs = new ObsSimilarityResponse();
  getObsForRegion(region){
    this.initializeChart('Comparison of supply and demand','Audit sections');
    this.showAllRegionObsTable = false;
    this.showObsRegionTable = true;
    this.showSelection = false;
    this.showSelectionRegion = false;
    this.showAllRegionTable = false;
    this.regionAllNotClick = false;

    this.breadcrumb3Visible = true;
    this.obsNotClick = false;
    this.breadcrumb3 = ('<a href="javascript:void(0)"> /'+this.curObservatory.obsName +'</a> / compare with '+ region)

    var result: ObsSimilarityResponse;
    for(let e of this.obsSimilarityRes.responses){
      if(e.region === region){
        result = e;
        this.curRegionObs = e;
      }
    }

/*TODO average line not working

    this.chart.options.yAxis[0].plotLines = [{
      label: {text: 'Average'},
      color: 'red',
      dashStyle: 'shortdot',
      value: +result.resultsPos[6].toFixed(3),
      width: 2
    }]*/


    this.textSelection = 'Details of the observatory ' + this.obsSimilarityRes.obsName + ' compared with the region '+ region;
    this.chart.addSeries({name: "RO values",           type: 'column',
      data: [+result.resultsPos[0].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "RO datasets",          type: 'column',
      data: [+result.resultsPos[1].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "RO services",          type: 'column',
      data: [+result.resultsPos[2].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "RO channels",           type: 'column',
      data: [+result.resultsPos[3].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "Pay services",          type: 'column',
      data: [+result.resultsPos[4].toFixed(3)]}, true, false);
    this.chart.addSeries({name: "Cooperations",          type: 'column',
      data: [+result.resultsPos[5].toFixed(3)]}, true, false);

  }

  getColorCountry(country) {
    switch (country) {
      case 'Austria': {
        return "rgb(51, 153, 255)";
      }
      case 'Czech Republic': {
        return "rgb(102, 51, 0)";
      }
      case 'Czech Rep.': {
        return "rgb(102, 51, 0)";
      }
      case 'Germany': {
        return "rgb(51, 102, 0)";
      }
      case 'Hungary': {
        return "rgb(255, 173, 51)";
      }
      case 'Italy': {
        return "rgb(204, 0, 0)";
      }
      case 'Poland': {
        return "rgb(204, 153, 255)";
      }
      case 'Slovenia': {
        return "rgb(102, 102, 153)";
      }
    }

  }

  initializeChart(titleText, xAxis) {
    this.chart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: titleText
      },
      xAxis:[{
        title: {
          text: xAxis
        },
        labels:
          {
            enabled: false
          },
        categories: [''],
        crosshair: true
      }],
      yAxis: [{
        title: {
          text: 'Results'
        },
      }
      ],
      credits: {
        enabled: true
      }
    });

  }


  chart = new Chart({
    chart: {
      type: 'column'
    },
    title: {
      text: 'Comparison of supply and demand'
    },
    xAxis: [{
      title: {
        text: 'Observatory Names'
      },
      labels:
        {
          enabled: false
        },
      categories: [''],
      crosshair: true
    }],
    yAxis: [{
      title: {
        text: 'Results'
      },
    }
    ],
    credits: {
      enabled: true
    }
  });


}

import {DemandComponent} from "./demand.component";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../material/material.module";
import {HttpModule} from "@angular/http";
import {HttpClientModule} from "@angular/common/http";
import {
  MatCardModule, MatDialogModule, MatExpansionModule, MatSidenavModule, MatSlideToggleModule, MatSortModule,
  MatTableModule,
  MatTooltipModule
} from "@angular/material";
import {RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import map from 'highcharts/modules/map.src';
import exporting from 'highcharts/modules/exporting.src'
import {Popup_infoComponent} from "./popup_info.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpModule,
    HttpClientModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    RouterModule,
    MatCardModule,
    ChartModule,
    MatDialogModule,
    FormsModule,
    MatSidenavModule,
    MatExpansionModule,
    ChartModule,
    MatSlideToggleModule,
    BrowserAnimationsModule
  ],
  exports: [
    DemandComponent
  ],
  declarations: [
    DemandComponent,
    Popup_infoComponent
  ],providers: [
  ],
  entryComponents: [
    Popup_infoComponent

  ]
})
export class DemandModule {}

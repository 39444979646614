
export class Insititution {
  id: string = '';
  name: string = '';
  address: string = '';
  zipCode: string = '';
  place: string = '';
  country:string = '';
  region:string = '';
  internalId:string = '';
  active: boolean = true;

  static empty(): Insititution {
    return {
      id : '',
      name: '',
      address: '',
      zipCode: '',
      place: '',
      country: '',
      region: '',
      internalId: '',
      active: true
    };
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule} from '@angular/material';
import {NetworkStatusService} from './network-status-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from '../material/material.module';
import {LoadingIndicatorComponent} from './loading-indicator.component';
import {NetworkUnavailableDialogComponent} from "./network-unavailable-dialog.component";

@NgModule({
  declarations: [
    LoadingIndicatorComponent,
    NetworkUnavailableDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  exports: [
    LoadingIndicatorComponent,
    NetworkUnavailableDialogComponent
  ],
  entryComponents: [
    NetworkUnavailableDialogComponent
  ],
  providers: [
    NetworkStatusService
  ]
})
export class NetworkStatusModule { }

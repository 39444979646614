import {Component, OnInit} from '@angular/core';
import {environment} from '../environments/environment';
import {Router} from "@angular/router";
import {AuthService} from "./auth/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  ngOnInit(): void {
  }

  constructor(public router: Router , public authService: AuthService) {

  }
  get isHome(){
    return ('/' === this.router.url);
  }

  get showBuildInfo(): boolean{
    return environment.production;
  }

  get isProductionEnvironment(): boolean {
    return environment.environmentName === 'prod';
  }

  get env() {
    return environment;
  }

  openRegionalEconomyStatistic(){
    this.router.navigate(['/regionalEconomy']);
  }

  openSectorsStatistic(){
    this.router.navigate(['/regionSector']);
  }

  openInnovationStatistic(){
    this.router.navigate(['/innovation']);
  }

  openCompStatistic(){
    this.router.navigate(['/competitiveness']);
  }

  openLogout(){
    this.router.navigate(['/logout']);
  }
  openChangePW(){
    this.router.navigate(['/changePassword']);
  }

  openAddUser(){
    this.router.navigate(['/createUser']);
  }

  openCreateInstitution(){
    this.router.navigate(['/createInstitution'])
  }



}

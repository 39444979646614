export class User {
  name: string = '';
  firstname: string = '';
  lastname: string = '';
  email: string = '';
  password: string = '';
  institutionID:string = '';
  activated:boolean = true;
  userRole: string = '';

  static empty(): User {
    return {
      name: '',
      firstname: '',
      lastname: '',
      password: '',
      email: '',
      institutionID: '',
      userRole: '',
      activated: true
    };
  }

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Util} from "../benchmarking/util";

@Component({
  selector: 'sw-multiple-choice-checkbox-w',
  template: `


    <mat-radio-group>
      <ng-container *ngFor="let opt of _options; let i = index">
        <mat-checkbox  [(ngModel)]="opt.selected" (ngModelChange)="selectionChanged()"  style="margin-left: 10px;"
                      [value]="opt.value">{{opt.displayValue}}
        </mat-checkbox>
        <mat-form-field class="input-width"  *ngIf="comments && opt.selected" >
    <textarea matInput  (blur)="commentTextChanged()" [(ngModel)]="_dictSelected[opt.value]"
              placeholder="Please specify:"></textarea>
        </mat-form-field> <br>
      </ng-container>

    </mat-radio-group>
  `
})
export class MultipleChoiceCheckboxWithoutComponent {
  _options: CheckBoxOptionState[];
  _dictSelected = {};

  @Output()
  selectedChange: EventEmitter<string[]> = new EventEmitter<string[]>();


  get options(): CheckboxOption[] {
    return this._options;
  }

  @Input()
  set options(opts: CheckboxOption[]) {
    this._options = opts.map(o => CheckBoxOptionState.fromCheckboxOption(o));
  }

  @Input()
  set optionTexts(options: string[]) {
    this.options = options.map(o => new CheckboxOption(o, o));
  }

  @Input()
  set dictSelected(values: { [id: string]: string }) {
    this._dictSelected = values;
  }

  get selected(): string[] {
    return this._options.filter(o => o.selected).map(o => o.value);
  }

  @Input()
  set selected(values: string[]) {
    const oldSelected = this.selected;

    if (values == null) {
      console.log('selected values null');
      return;
    }

    if (Util.arrayEquals(values, oldSelected)) {
      return;
    }

    this._options.forEach(o => o.selected = false);
    values.forEach(cur => {
      const opt = this._options.find(o => o.value === cur);
      if (opt != null) {
        opt.selected = true;
      }
    });

    const newSelected = this.selected;

    if (!Util.arrayEquals(oldSelected, newSelected)) {
      this.selectedChange.emit(this.selected);
    }
  }

  @Input()
  set selectedDict(values: {[id:string]:string}) {
    const oldSelected = this.selected;

    if (values == null) {
      console.log('selected values null');
      return;
    }

    if (Util.arrayEquals(Object.keys(values), oldSelected)) {
      return;
    }

    this._options.forEach(o => o.selected = false);
    Object.keys(values).forEach(cur => {
      const opt = this._options.find(o => o.value === cur);
      if (opt != null) {
        opt.selected = true;
      }
    });

    const newSelected = this.selected;

    if (!Util.arrayEquals(oldSelected, newSelected)) {
      this.selectedChange.emit(this.selected);
    }
  }



  selectionChanged() {
    this.selectedChange.emit(this.selected);
  }

}

export class CheckboxOption {
  constructor(public displayValue: string = '', public value: string = '') {
  }

  static fromChoices(...values: string[]): CheckboxOption[] {
    return values.map(v => new CheckboxOption(v, v));
  }

  private static generateCheckBoxValue(displayValue: string, normalize: boolean) {
  }
}

class CheckBoxOptionState extends CheckboxOption {
  selected = false;

  static fromCheckboxOption(opt: CheckboxOption): CheckBoxOptionState {
    return {
      displayValue: opt.displayValue,
      value: opt.value,
      selected: false
    };
  }
}

import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {CdkScrollable} from "@angular/cdk/scrolling";
import {MAT_DIALOG_DATA} from "@angular/material";
@Component({
  selector: 'sw-popup-data',
  styles: [`
    a {
      color: black;
      text-decoration: underline;
    }
  `],
  template: `
    <mat-card>
      <div *ngFor="let s of categoriesDescription">
        <li>{{s}}</li>
      </div>
    </mat-card>

  `
})
export class PopupDataComponent implements OnInit{
  categoriesDescription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data) {


  }
  @ViewChild(CdkScrollable) scrollable: CdkScrollable;

  ngOnInit(): void {
    console.log(this.data.categoriesDescription)
    this.categoriesDescription = this.data.categoriesDescription;

  }


}

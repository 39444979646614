export class Register{
  name : string = '';
  institution : string = '';
  institutionWebsite : string = '';
  email : string = '';
  country : string = '';
  region : string = '';
  active: boolean = true;

  static empty(): Register {
    return {
      name: '',
      institution: '',
      institutionWebsite: '',
      email: '',
      country: '',
      region: '',
      active: true
    };
  }
}

import {EventEmitter, Injectable, Input, Output} from '@angular/core';
import {Observable, Subscription, Subject, BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class NetworkStatusService {
  readonly pollingIntervalOnline = 20 * 1000;
  readonly pollingIntervallOffline = 5 * 1000;

  readonly pollingTimeout = 5 * 1000;
  readonly pollingUrl: string;
  private _pendingRequests = 0;
  private connectivityPoller: Subscription;
  private _isOnline = true;
  private _lastSuccessfulConnection: Date = new Date();

  constructor(public http: HttpClient) {
    this.pollingUrl = environment.apiUrl + 'status/serviceinfo';
  }

  isOnline = new BehaviorSubject<boolean>(true);

  get lastSuccessfulConnection(): Date {
    return this._lastSuccessfulConnection;
  }
  get isLoading() {
    return this._pendingRequests > 0;
  }
  isLoadingChange = new BehaviorSubject<boolean>(false);

  connectionLost = new Subject();
  connectionEstablished = new Subject();

  startRequest() {
    if (this._pendingRequests === 0) {
      this.isLoadingChange.next(true);
    }
    this._pendingRequests++;

  }

  stopRequest() {
    if (this._pendingRequests === 1) {
      this.isLoadingChange.next(false);
    }
    this._pendingRequests--;
    if (this._pendingRequests < 0) {
      this._pendingRequests = 0;
    }
  }

  private createConnectionPoller(interval: number) {
    console.log(`Starting connection poller with ${interval}ms.`);
    this.stopConnectivityCheck();
    this.connectivityPoller = Observable.interval(interval)
      .switchMap(() => this.http.get(this.pollingUrl))
      .subscribe( {
        error: (e) => this.connectivityError(e),
        next: () => this.connectivityOk(),
        // complete: () => this.connectivityOk()
      });
  }

  startConnectivityCheck() {
    this.stopConnectivityCheck();
    this.createConnectionPoller(this.pollingIntervalOnline);

  }
  stopConnectivityCheck() {
    if (this.connectivityPoller != null) {
      this.connectivityPoller.unsubscribe();
      this.connectivityPoller = null;
    }
  }
  private connectivityError(e) {
    if (this._isOnline) {
      this.createConnectionPoller(this.pollingIntervallOffline);
      this._isOnline = false;
      this.connectionLost.next();
      this.isOnline.next(true);
    }
  }
  private connectivityOk() {
    this._lastSuccessfulConnection = new Date();
    if (!this._isOnline) {
      this.createConnectionPoller(this.pollingIntervalOnline);
      this._isOnline = true;
      this.connectionEstablished.next();
      this.isOnline.next(true);
    }
  }

}

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';

@Component({
  selector: 'sw-network-unavailable-dialog',
  styles: [`.centered{
    display: block;
    margin-left: auto;
    margin-right: auto
  }`,
  `
    .icon{
      font-size: 48px;
    }
    `],
  template:`
    <h2 mat-dialog-title><i class="material-icons icon">cloud_off</i> Connectivity problems</h2>
    <mat-dialog-content>
      <div>
        <p>
        The application lost the connection to the backend server.
        </p>
        <p>
          Please wait while the application re-establishes the connection.
        </p>
      </div>
      <div>
        <mat-progress-spinner class="centered" color="warn" mode="indeterminate">

        </mat-progress-spinner>
      </div>
    </mat-dialog-content>`
})
export class NetworkUnavailableDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NetworkUnavailableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}

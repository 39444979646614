export class Innovation {
  country: string = '';
  region : string = '';
  shortname : string = '';
  data : InnovationData[] = [];
}

export class InnovationData{
  year2009 : number = 0;
  year2012 : number = 0;
  year2014 : number = 0;
  year2017 : number = 0;

  constructor(year2009,year2012,year2014,year2017){
    this.year2012 = year2012;
    this.year2017 = year2017;
    this.year2014 = year2014;
    this.year2009 = year2009;
  }
}

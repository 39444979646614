<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">{{title}}</h1>
    </div>
  </div>
</header>
<mat-card class="mat-card-align">
  <div>{{resError$}}</div>


  <mat-form-field class="input-width">
    <textarea matInput id="name" [(ngModel)]="institution.name" placeholder="Name" class="input-width"></textarea>
  </mat-form-field><br>

  <mat-form-field class="input-width">
    <textarea matInput id="address" [(ngModel)]="institution.address" placeholder="Address" class="input-width"></textarea>
  </mat-form-field><br>

  <mat-form-field class="input-width">
    <textarea matInput id="zip" [(ngModel)]="institution.zipCode" placeholder="Zip Code" class="input-width"></textarea>
  </mat-form-field><br>

  <mat-form-field class="input-width">
    <textarea matInput id="place" [(ngModel)]="institution.place" placeholder="Place" class="input-width"></textarea>
  </mat-form-field><br>


    <mat-form-field class="input-width">
      <mat-select (closed)="setRegions()" [(ngModel)]="institution.country" placeholder="Country"  id="country">
        <mat-option value="Austria">Austria</mat-option>
        <mat-option value="Czech Republic">Czech Republic</mat-option>
        <mat-option value="Germany">Germany</mat-option>
        <mat-option value="Hungary">Hungary</mat-option>
        <mat-option value="Italy">Italy</mat-option>
        <mat-option value="Poland">Poland</mat-option>
        <mat-option value="Slovenia">Slovenia</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field class="input-width" >
      <mat-select placeholder="Region" [(ngModel)]="institution.region" id="region" [disabled]="selectDisabled">
        <mat-option *ngFor="let region of regionSelected.value" [value]="region.name">
          {{region.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  <br>

  <mat-form-field class="input-width">
    <textarea matInput id="internalId" [(ngModel)]="institution.internalId" placeholder="Internal ID" class="input-width"></textarea>
  </mat-form-field><br>

  <mat-checkbox matInput id="active" [(ngModel)]="institution.active">Active</mat-checkbox>

  <mat-card-actions class="input-width">
    <button class="btn-align" mat-fab type="submit" (click)="onSubmit()"><i class="material-icons">done</i></button>
  </mat-card-actions><br>

</mat-card>



import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import {IndexComponent} from "./index/index.component";
import {BenchmarkingModule} from "./benchmarking/benchmarking.module";
import {AuthModule} from "./auth/auth.module";
import {MaterialModule} from "./material/material.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from "./app-routing.module";
import {MatDialogModule} from "@angular/material";
import {NetworkStatusModule} from "./network-status/NetworkStatusModule";
import {AuthGuard} from "./auth/authGuard";
import {HttpClientModule} from "@angular/common/http";
import {FrameworkModule} from "./framework/framework.module";
import {DemandModule} from "./demand-overlay/demand.module";
import {SwOverlayModule} from "./overlay/swOverlay.module";


@NgModule({
  declarations: [
    AppComponent,
    IndexComponent
  ],
  imports: [
    BrowserModule,
    BenchmarkingModule,
    FrameworkModule,
    DemandModule,
    AuthModule,
    MaterialModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    NetworkStatusModule,
    HttpClientModule,
    SwOverlayModule,

  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }

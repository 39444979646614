<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1"><a routerlink="benchlearning" href="benchlearning" mat-button style='color: rgba(255, 255, 255, 0.87)'>

        <span><i class="material-icons" matTooltip="Pilot Action Report">arrow_back</i> </span>
      </a>Print Audit Report - {{pdfParameterRequest.name}}</h1>
    </div>
  </div>
</header>
<mat-card class="mat-card-align">
  <div>{{resErrorName$}}</div>
  <mat-card>
    <mat-card-header><h4>Dissemination Level</h4></mat-card-header>
    <mat-select matInput id="dissemination" [(ngModel)]="pdfParameterRequest.dissemination" class="input-width">
      <mat-option value="Public">Public</mat-option>
      <mat-option value="Restricted">Restricted</mat-option>
      <mat-option value="Confidential">Confidential</mat-option>
    </mat-select>
  </mat-card>

  <mat-card>
    <mat-card-header><h4>Quality Control</h4></mat-card-header>
    <mat-form-field class="input-width">
      <textarea matInput id="quality" [(ngModel)]="pdfParameterRequest.quality" placeholder="please enter" class="input-width">
      </textarea>
    </mat-form-field>
  </mat-card>

  <mat-card>
    <mat-card-header><h4>Version</h4></mat-card-header>
    <mat-form-field class="input-width">
      <textarea matInput id="version" [(ngModel)]="pdfParameterRequest.versions.version" placeholder="please enter version" class="input-width">
      </textarea>
    </mat-form-field><br>
    <mat-form-field class="input-width">
      <textarea matInput id="editor" [(ngModel)]="pdfParameterRequest.versions.editor" placeholder="please enter author/ editor/ reviewer" class="input-width">
      </textarea>
    </mat-form-field><br>
    <mat-form-field class="input-width">
      <textarea matInput id="contributer" [(ngModel)]="pdfParameterRequest.versions.contributer" placeholder="please enter contributer" class="input-width">
      </textarea>
    </mat-form-field><br>
    <mat-form-field class="input-width">
      <textarea matInput id="comments" [(ngModel)]="pdfParameterRequest.versions.comments" placeholder="please enter comments" class="input-width">
      </textarea>
    </mat-form-field>
  </mat-card>
  <mat-card-actions class="input-width">
    <button class="btn-align" mat-fab type="submit" (click)="onSubmit()"><i class="material-icons">print</i></button>
  </mat-card-actions>

</mat-card>


<mat-toolbar *ngIf="isHome " color="primary" class="navbar-shadow nav">

  <div _ngcontent-c1="" class="flex-spacer">
    <img *ngIf="!isHome" src="assets/SMART_watch_small-surface_horizontal.png" style="width:100%;height:auto;max-width:300px;min-width:100px;">
    <span class="environment" *ngIf="!isProductionEnvironment">!!! {{env.environmentName}} !!!</span>
  </div>

  <a *ngIf="!isHome" routerlink="/" matTooltip="Home" routerLinkActive="active" _ngcontent-c1="" mat-button="" href="/"   style='color: rgba(255, 255, 255, 0.87)' mattooltip="Start page">
    <i class="material-icons md-light md-14">home</i> Home
  </a>

  <button [matMenuTriggerFor]="menuUser" *ngIf="authService.hasToken()" routerLinkActive="active" _ngcontent-c1="" mat-button=""
          matTooltip="Click to modify your profile or to loggout">
    <mat-icon>account_circle</mat-icon>
    <span>{{authService.tokenInfo.userId}}</span>
  </button>
  <mat-menu #menuUser="matMenu"  [overlapTrigger]="false">
    <button mat-menu-item (click)="openChangePW()" matTooltip="Update Account">
      <mat-icon>account_circle</mat-icon>
      <span>Update Account</span>
    </button>
    <button mat-menu-item (click)="openAddUser()" matTooltip="Create User" *ngIf="authService.isPartnerOrLeadPartnerOrAdmin()">
      <mat-icon>person_add</mat-icon>
      <span>Create User</span>
    </button>
    <button mat-menu-item (click)="openCreateInstitution()" matTooltip="Create Institution" *ngIf="authService.isAdmin()">
      <mat-icon>add</mat-icon>
      <span>Create Institution</span>
    </button>
    <button mat-menu-item (click)="openLogout()" matTooltip="Logout">
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>

  <a *ngIf="!authService.hasToken()" routerLink="/login" routerLinkActive="active" _ngcontent-c1="" mat-button=""  style='color: rgba(255, 255, 255, 0.87)' >
    <i class="material-icons md-light md-14">exit_to_app</i> Login
  </a>

  <a *ngIf="!authService.hasToken()" routerLink="/register" routerLinkActive="active" _ngcontent-c1="" mat-button=""  style='color: rgba(255, 255, 255, 0.87)' >
    <i class="material-icons md-light md-14">how_to_reg</i> Register
  </a>


</mat-toolbar>

<mat-toolbar *ngIf="!isHome" color="white" class="navbar-shadow nav">
  <div _ngcontent-c1="" class="flex-spacer">
    <a href="/" routerlink="/" ><img *ngIf="!isHome" src="assets/SMART_watch_small-surface_horizontal.png" style="width:100%;height:auto;max-width:300px;min-width:100px;"></a>
    <span class="environment" *ngIf="!isProductionEnvironment">!!! {{env.environmentName}} !!!</span>
  </div>

  <a *ngIf="!isHome" routerlink="/" matTooltip="Home" routerLinkActive="active" _ngcontent-c1="" mat-button="" href="/"   style='color: #3f51b5' mattooltip="Start page">
    <i class="material-icons md-light md-14 homebutton">home</i><span class="homebutton">Home</span>
  </a>


  <button  *ngIf="!isHome && authService.hasToken()" [matMenuTriggerFor]="statistic"  style='color:#3f51b5'
          routerLinkActive="active"  mat-button="" _ngcontent-c1="" matTooltip="Click to see available statistics">
    <mat-icon style='color:#3f51b5'>description</mat-icon>
    <span style='color:#3f51b5'>Regional Statistics</span>
  </button>

  <mat-menu #statistic="matMenu">
    <button mat-menu-item (click)="openRegionalEconomyStatistic()" matTooltip="Regional Economy Statistic">
      <mat-icon>assignment</mat-icon>
      <span>Regional economy statistic</span>
    </button>
  <button mat-menu-item (click)="openSectorsStatistic()" matTooltip="Sectors of the region statistic">
      <mat-icon>assignment</mat-icon>
      <span>Sectors of the region statistic</span>
    </button>
    <button mat-menu-item (click)="openInnovationStatistic()" matTooltip="Innovation statistic">
      <mat-icon>assignment</mat-icon>
      <span>Innovation statistic</span>
    </button>
    <button mat-menu-item (click)="openCompStatistic()" matTooltip="Competitiveness statistic" >
      <mat-icon>assignment</mat-icon>
      <span>Competitiveness statistic</span>
    </button>

  </mat-menu>

  <a *ngIf="!isHome && authService.hasToken()" routerlink="/" matTooltip="Benchlearning" routerLinkActive="active" _ngcontent-c1="" mat-button="" href="/benchlearning"   style='color: #3f51b5' mattooltip="Benchlearning page">
    <i class="material-icons md-light md-14 ">assignment</i><span>Benchlearning</span>
  </a>

  <a *ngIf="!isHome && authService.hasToken()" routerlink="/" matTooltip="Performance" routerLinkActive="active" _ngcontent-c1="" mat-button="" href="/demand"   style='color: #3f51b5' mattooltip="Performance page">
    <i class="material-icons md-light md-14 ">bar_chart</i><span>Performance</span>
  </a>

  <a *ngIf="!isHome && authService.hasToken()" routerlink="/" matTooltip="Overlay" routerLinkActive="active" _ngcontent-c1="" mat-button="" href="/overlay"   style='color: #3f51b5' mattooltip="Overlay page">
    <i class="material-icons md-light md-14 ">bar_chart</i><span>Service Overlay</span>
  </a>

  <button [matMenuTriggerFor]="menuUser" *ngIf="authService.hasToken() && authService.hasToken()" routerLinkActive="active" _ngcontent-c1="" mat-button=""
          matTooltip="Click to modify your profile or to loggout" style='color:#3f51b5'>
    <mat-icon>account_circle</mat-icon>
    <span>{{authService.tokenInfo.userId}}</span>
  </button>
  <mat-menu #menuUser="matMenu"  [overlapTrigger]="false">
    <button mat-menu-item (click)="openChangePW()" matTooltip="Update Account">
      <mat-icon>account_circle</mat-icon>
      <span>Update Account</span>
    </button>
    <button mat-menu-item (click)="openAddUser()" matTooltip="Create User" *ngIf="authService.isPartnerOrLeadPartnerOrAdmin()">
      <mat-icon>person_add</mat-icon>
      <span>Create User</span>
    </button>
    <button mat-menu-item (click)="openCreateInstitution()" matTooltip="Create Institution" *ngIf="authService.isAdmin()">
      <mat-icon>add</mat-icon>
      <span>Create Institution</span>
    </button>
    <button mat-menu-item (click)="openLogout()" matTooltip="Logout">
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>

  <a *ngIf="!authService.hasToken()" routerLink="/login" routerLinkActive="active" _ngcontent-c1="" mat-button="" style='color:#3f51b5'>
    <i class="material-icons md-light md-14">exit_to_app</i> Login
  </a>
</mat-toolbar>

<router-outlet></router-outlet>
<div class="spacer" *ngIf="isHome">&nbsp;</div>




<footer _ngcontent-c10="" class="docs-footer" style="background: #3f51b5;">
  <span  style="color: #ffffff">&copy; 2019 <a  style="color: #ffffff" href="http://www.interreg-central.eu/Content.Node/SMART-watch.html" target="_blank">SMART_watch</a></span>
  <br/>
  <span style="font-size: 4pt; color: #3f51b5;" *ngIf="showBuildInfo">
  commit: '{{env.commitHash}}' from '{{env.commitDate}}' deployed on '{{env.buildDate}}'
  </span>

</footer>

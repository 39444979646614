<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">
       Service: Demand Side Overlay </h1>
    </div>
  </div>
</header>

    <div style="float: left; width: 15%; padding: 1px; margin-bottom: 60px">
      <h6>Filter Categories:</h6>
      <table>
        <tr>
          <td>
            <mat-checkbox class="input-width" [(ngModel)]="filter.data"
                          (change)="filterChart()"
                          value="data"> Dataset
            </mat-checkbox>
          </td>
          <td>
            <span style="background-color: rgb(51, 153, 255); ">&nbsp;&nbsp;&nbsp;</span>
          </td>
        </tr>


        <tr>
          <td>
            <mat-checkbox class="input-width" [(ngModel)]="filter.report"
                          (change)="filterChart()"
                          value="reports">Reports
            </mat-checkbox>
          </td>
          <td><span style="background-color: rgb(255, 173, 51)">&nbsp;&nbsp;&nbsp;</span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox class="input-width" [(ngModel)]="filter.services"
                          (change)="filterChart()"
                          value="services">Services
            </mat-checkbox>
          </td>
          <td style="position: absolute;">
            <span style="background-color: rgb(102, 102, 153)">&nbsp;&nbsp;&nbsp;</span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox class="input-width" [(ngModel)]="filter.trends"
                          (change)="filterChart()"
                          value="trends">Trends
            </mat-checkbox>
          </td>
          <td style="position: absolute;"><span style="background-color: rgb(102, 51, 0)">&nbsp;&nbsp;&nbsp;</span></td>
        </tr>
        <tr>
          <td>
            <mat-checkbox class="input-width" [(ngModel)]="filter.platform"
                          (change)="filterChart()"
                          value="platforms">Platforms
            </mat-checkbox>
          </td>
          <td style="position: absolute;">
            <span style="background-color: rgb(204, 0, 0)">&nbsp;&nbsp;&nbsp;</span>
          </td>
        </tr>
      </table>

      <h6>Filter Region</h6>
      <mat-form-field class="input-width" style="width: 30px; border: none">
        <mat-select [(ngModel)]="filter.region"
                    placeholder="Region"
                    id="region"
        style="margin-top: 0px; border: none">
          <mat-option *ngFor="let r of filter.regions" [value]="r" (click)="filterChart()">
            {{r}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <h6>Filter areas of smart specialisation</h6>
      <sw-multiple-choice-checkbox-w
        (selectedChange)="filterChart()"
        [options]="currentValueOptions"
        [(selected)]="filter.area"
        placeholder="Area(s) of smart specialisation">
      </sw-multiple-choice-checkbox-w>

      <button mat-mini-fab color="primary" matTooltip="Information" (click)="openDialogInfo()"
              style="margin:5px;">
        <mat-icon aria-label="info">info</mat-icon>
      </button>
    </div>

    <div style="float: right; width: 85%; margin-bottom: 60px">
      <ng-container *ngFor="let d of chartsArray; let i = index">
        <div style="float: left;" [chart]="chartsArray[i]"></div>
      </ng-container>
    </div>



import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MatRadioGroup, MatSelect} from "@angular/material";
import {Insititution} from "./insititution";
import {DataService} from "../../data-service";

@Component({
  templateUrl: './createInstitution.component.html',
  styleUrls: ['./createInstitution.component.css']
})
export class CreateInstitutionComponent implements OnInit {

  institution = Insititution.empty();
  resError$;
  title;
  private id;

  ngOnInit(): void {
    this.id = this.router.url.split('/')[2];
    if(this.id){
      this.title = 'Edit Institution'
      this.dataService.getSingleInsitution(this.id).subscribe(
        r =>{
          this.institution = r;
        }
      )
    }else{
      this.title = 'Create Institution'
    }
  }

  constructor(public dataService: DataService, private router: Router) {}

  onSubmit() {
    if (this.id) {
      this.dataService.updateInstitution(this.id,this.institution).subscribe(
        r => {
          this.router.navigate(['/manageInstitution']);
        }
      );
    } else {
      this.dataService.createInsitution(this.institution).subscribe(
        r => {
          if (r.name.indexOf(this.institution.name) > -1) {
            this.resError$ = "Institution successfully created"
          }
        }
      )
    }
  }

  regionSelected = {  value: []};
  selectDisabled = true;

  regions = {
    'Austria': {value: [{name: 'Burgenland'}, {name: 'Kärnten'}, {name: 'Niederösterreich'}, {name: 'Oberösterreich'}
      , {name: 'Salzburg'}, {name: 'Steiermark'}, {name: 'Tirol'}, {name: 'Vorarlberg'}, {name: 'Wien'}]},
    'CzechRepublic' : {value:  [{name: 'Jihovychod'}, {name: 'Jihozapad'}, {name: 'Moravskoslezsko'}, {name: 'Praha'},
      {name: 'Severovychod'}, {name: 'Severozapad'}, {name: 'Stredni Cechy'}, {name: 'Stredni Morava'}]},
    'Germany': {value: [{name: 'Baden-Württemberg'}, {name: 'Bayern'}, {name: 'Berlin'}, {name: 'Brandenburg'}, {name: 'Bremen'},
      {name: 'Hamburg'}, {name: 'Hessen'}, {name: 'Mecklenburg-Vorpommern'}, {name: 'Niedersachsen'}, {name: 'Nordrhein-Westfalen'},
      {name: 'Rheinland-Pfalz'}, {name: 'Saarland'}, {name: 'Sachsen'}, {name: 'Sachsen-Anhalt'}, {name: 'Schleswig-Holstein'}, {name: 'Thüringen'}]},
    'Hungary': {value: [{name: 'Del-Alfold'}, {name: 'Del-Dunantul'}, {name: 'Eszak-Alfold'}, {name: 'Eszak-Magyarorszag'}, {name: 'Kozep-Dunantul'},
      {name: 'Kozep-Magyarorszag'}, {name: 'Nyugat-Dunantul'}]},
    'Italy': {value: [{name: 'Abruzzo'}, {name: 'Basilicata'}, {name: 'Calabria'}, {name: 'Campania'}, {name: 'Emilia-Romagna'},
      {name: 'Friuli-Venezia Giulia'}, {name: 'Lazio'}, {name: 'Liguria'}, {name: 'Lombardia'},
      {name: 'Marche'}, {name: 'Molise'}, {name: 'Piemonte'},
      {name: 'Provincia Autonoma Trento'}, {name: 'Puglia'}, {name: 'Sardegna'}, {name: 'Sicilia'},
      {name: 'Toscana'}, {name: 'Umbria'}, {name: 'Valle d\'Aosta/Vallée d\'Aoste'}, {name: 'Veneto'}]},
    'Poland': {value: [{name: 'Dolnoslaskie'}, {name: 'Kujawsko-Pomorskie'}, {name: 'Lodzkie'}, {name: 'Lubelskie'}, {name: 'Lubuskie'},
      {name: 'Malopolskie'}, {name: 'Mazowieckie'}, {name: 'Opolskie'}, {name: 'Podkarpackie'},
      {name: 'Podlaskie'}, {name: 'Pomorskie'}, {name: 'Slaskie'}, {name: 'Swietokrzyskie'}, {name: 'Warminsko-Mazurskie'}, {name: 'Wielkopolskie'}, {name: 'Zachodniopomorskie'}]},
    'Slovenia': {value: [{name: 'Vzhodna Slovenija'}, {name: 'Zahodna Slovenija'}]}
  };

  setRegions(): void {
    this.selectDisabled = false;
    switch (this.institution.country) {
      case 'Austria': {
        this.regionSelected = {value: this.regions.Austria.value};
        break;
      }
      case 'Czech Republic': {
        this.regionSelected = {value: this.regions.CzechRepublic.value};
        break;
      }
      case 'Germany': {
        this.regionSelected = {value: this.regions.Germany.value};
        break;
      }
      case 'Hungary': {
        this.regionSelected = {value: this.regions.Hungary.value};
        break;
      }
      case 'Italy': {
        this.regionSelected = {value: this.regions.Italy.value};
        break;
      }
      case 'Poland': {
        this.regionSelected = {value: this.regions.Poland.value};
        break;
      }
      case 'Slovenia': {
        this.regionSelected = {value: this.regions.Slovenia.value};
        break;
      }
    }
  }
}

<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">
      <!--  <a routerlink="benchlearning" href="benchlearning" mat-button style='color: rgba(255, 255, 255, 0.87)'>
          <span><i class="material-icons" matTooltip="Benchlearning Overview">arrow_back</i> </span>
        </a>-->
        Benchlearning Details - {{benchlearning.name}} </h1>
    </div>
  </div>
</header>

<span style="color:#3f51b5; margin-left: 1%" [innerHTML]="breadcrumb" (click)="getToStartPage()"></span>
<span style="color:#3f51b5;">Details</span>

<div class="example-container mat-elevation-z8">

  <table matSort class="table" style="margin-bottom:100px;">
    <h4>General RO Information<img src="assets/info.png" style="width:100%;height:auto;max-width:20px;margin-left: 5px"></h4>
    <tr>
      <th width="14%">Name</th>
      <th width="14%">Country</th>
      <th width="14%">Region</th>
      <th width="30%">Area(s) of smart specialisation</th>
      <th width="14%">Policy / Technology</th>
      <th width="14%">Main target</th>
    </tr>
    <tr>
      <td width="14%">{{benchlearning.name}}</td>
      <td width="14%">{{benchlearning.country}}</td>
      <td width="14%">{{benchlearning.region}}</td>
      <td width="30%">{{getAreasSmartSpec()}}</td>
      <td width="14%">{{getPolicyTechnology()}}</td>
      <td width="14%">{{benchlearning.cusSegmainRoTarget}}</td>
    </tr>

    <h4>Value Proposition Data<img src="assets/data.png" style="width:100%;height:auto;max-width:20px;margin-left: 5px"></h4>
    <tr>
      <th colspan="3" width="50%">Current Value....</th>
      <th colspan="3" width="50%">Future Value for Customers</th>
    </tr>
    <tr>
      <td colspan="3" width="50%">{{benchlearning.valuePropcurrentValueCustomer}}</td>
      <td colspan="3" width="50%">{{benchlearning.valuePropfutureValueCustomer}}</td>
    </tr>
    <tr *ngIf="gatheredDataData">
      <th colspan="6">Gathered Data</th>
    </tr>
    <tr *ngIf="gatheredDataData">
      <td colspan="6">
      <ng-container *ngFor="let curGroup of benchlearning.valuePropgatheredData;let i = index">
        <div   *ngIf="benchlearning.valuePropgatheredData[i].items.length > 0">

        <h6>
          {{groupTitlesGatheredData[i]}}
        </h6>
        <table class="table">
          <tr>
            <th colspan="2">Dataset Name</th>
            <th colspan="2">Source of Data</th>
            <th colspan="2">Updated</th>
          </tr>
          <tr *ngFor="let curItem of benchlearning.valuePropgatheredData[i].items">
            <td colspan="2" width="50%">{{curItem.datasetName}}</td>
            <td colspan="2" width="25%">{{curItem.sourceOfData}}</td>
            <td colspan="2" width="25%">{{getValuePropGatheredDataUpdated(curItem.updated)}}</td>
          </tr>
        </table>
        </div>
      </ng-container>
      </td>
    </tr>

    <tr *ngIf="providedServicesData">
      <th colspan="6">Provided Services</th>
    </tr>
    <tr *ngIf="providedServicesData">
      <td colspan="6">
        <ng-container *ngFor="let curGroup of benchlearning.valuePropofferedServiceGroup;let i = index">
          <div   *ngIf="benchlearning.valuePropofferedServiceGroup[i].items.length > 0">

          <h6>
            {{groupTitlesServices[i]}}
          </h6>
          <table class="table">
            <tr>
              <th width="40%" colspan="3">Service Name</th>
              <th width="20%">Dissemination</th>
              <th width="20%">Price</th>
              <th width="20%">Nature</th>
            </tr>
            <tr *ngFor="let curItem of benchlearning.valuePropofferedServiceGroup[i].items">
              <td colspan="3" width="40%">{{curItem.datasetName}}</td>
              <td  width="20%">{{curItem.dissemination}}</td>
              <td width="20%">{{curItem.price}}</td>
              <td  width="20%">{{curItem.nature}}</td>
            </tr>
          </table>
          </div>
        </ng-container>
      </td>
    </tr>

    <h4>Key Resources<img src="assets/key.png" style="width:100%;height:auto;max-width:20px;margin-left: 5px"></h4>

    <tr>
      <th width="16%">Relevant competences for the RO's actions not available</th>
      <th width="28%" >Tools fundamental for the RO</th>
      <th width="28%" colspan="2" >Best Practices</th>
      <th width="28%" colspan="2">Tools improvement</th>
    </tr>
    <tr>
      <td width="16%">{{benchlearning.keyResmissingCompetence}}<span *ngIf="benchlearning.keyResmissingCompetence === false"> - {{benchlearning.keyResmissingCompetenceReason}}</span></td>
      <td width="28%">{{benchlearning.keyRestoolsAndProcedures}}</td>
      <td width="28%" colspan="2">{{benchlearning.keyResbestPractices}}</td>
      <td width="28%" colspan="2">{{benchlearning.keyRestoolsAndProceduresImprovement}}</td>
    </tr>
    <tr>
      <th colspan="6">Incomes covered by</th>
    </tr>
    <tr>
      <td colspan="6">
      <div class="year">
        <div>
          <span><b>2013 </b></span>
          <mat-radio-group [(ngModel)]="benchlearning.keyResbudgetCovered[0]" >
            <mat-radio-button   style="padding-right: 8px;" [value]="0">dedicated public funds</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="1">publicly funded projects</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="2">private financing</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <span><b>2014</b></span>
          <mat-radio-group [(ngModel)]="benchlearning.keyResbudgetCovered[1]" >
            <mat-radio-button   style="padding-right: 8px;" [value]="0">dedicated public funds</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="1">publicly funded projects</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="2">private financing</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <span><b>2015</b></span>
          <mat-radio-group [(ngModel)]="benchlearning.keyResbudgetCovered[2]"  >
            <mat-radio-button   style="padding-right: 8px;" [value]="0">dedicated public funds</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="1">publicly funded projects</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="2">private financing</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <span><b>2016</b></span>
          <mat-radio-group [(ngModel)]="benchlearning.keyResbudgetCovered[3]">
            <mat-radio-button   style="padding-right: 8px;" [value]="0">dedicated public funds</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="1">publicly funded projects</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="2">private financing</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <span><b>2017</b></span>
          <mat-radio-group [(ngModel)]="benchlearning.keyResbudgetCovered[4]" >
            <mat-radio-button   style="padding-right: 8px;" [value]="0">dedicated public funds</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="1">publicly funded projects</mat-radio-button>
            <mat-radio-button   style="padding-right: 8px;" [value]="2">private financing</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      </td>
    </tr>

    <h4>Key Partners<img src="assets/key-partners.png" style="width:100%;height:auto;max-width:20px;margin-left: 5px"></h4>
    <tr style="margin-top: 10px">
      <th colspan="6">Long Term Framework Partnerships</th>
    </tr>
    <tr>
      <th width="10%">Name of the partner</th>
      <th width="10%">Website of the partner</th>
      <th  width="29%">Key benefits for the RO</th>
      <th colspan="2" width="41%">Key benefits for the partner</th>
      <th width="10%">City</th>
    </tr>
    <tr *ngFor="let p of benchlearning.keyParpartnersFramework">
      <td width="10%">{{p.partnerName}}</td>
      <td width="10%"><a href="{{p.partnerWebSite}}">{{p.partnerWebSite}}</a></td>
      <td width="29%">{{p.keyBenefitsRo}}</td>
      <td colspan="2"  width="41%">{{p.keyBenefitsPartner}}</td>
      <td width="10%">{{p.city}}</td>
    </tr>

    <tr style="margin-top: 10px" *ngIf="operationalPartnershipData">
      <th colspan="6">Operational Partnerships</th>
    </tr>
    <tr *ngIf="operationalPartnershipData">
      <td colspan="6">
        <ng-container *ngFor="let curGroup of benchlearning.keyParpartnersOperational;let i = index">
          <div   *ngIf="benchlearning.keyParpartnersOperational[i].partnerships.length > 0">
          <h6>
            {{groupHeadersPartnership[i]}}
          </h6>
          <table class="table">
            <tr>
              <th width="14%">Name of the partner</th>
              <th width="14%">Website of the partner</th>
              <th colspan="2" width="29%">Key benefits for the RO</th>
              <th width="29%">Key benefits for the partner</th>
              <th width="14%">City</th>
            </tr>
            <tr *ngFor="let curItem of benchlearning.keyParpartnersOperational[i].partnerships">
              <td width="14%">{{curItem.partnerName}}</td>
              <td width="14%">{{curItem.partnerWebSite}}</td>
              <td colspan="2" width="29%">{{curItem.keyBenefitsRo}}</td>
              <td width="29%">{{curItem.keyBenefitsPartner}}</td>
              <td width="14%">{{curItem.city}}</td>
            </tr>
          </table>
          </div>
        </ng-container>
      </td>
    </tr>
    <tr>
      <th colspan="2">Factors for in-house development / subcontracting the competences: </th>
      <td colspan="4">{{benchlearning.keyParfactorsInHouse}}</td>
    </tr>

    <h4>Customer Relationships<img src="assets/crm.png" style="width:100%;height:auto;max-width:20px;margin-left: 5px"></h4>
    <tr>
      <th colspan="2">Improvemnet Credibility</th>
      <th>Tools for Mantaining</th>
      <th>Best Practices</th>
      <th>Customers Numbers</th>
      <th>Customers Numbers</th>
    </tr>
    <tr>
      <td colspan="2">{{benchlearning.cusRelimprovementCredibility}}</td>
      <td>{{benchlearning.cusReltoolsCustomerRel}}</td>
      <td>{{benchlearning.cusRelbestPractices}}</td>
      <td>{{getCustGrowth(benchlearning.cusRelcustomerGrowth)}}</td>
      <td>{{getCustChange(benchlearning.cusRelcustomerChange)}}</td>
    </tr>

    <tr>
      <td colspan="6">In the last year the RO served directly around <span *ngIf="benchlearning.cusRelnumCustomerServed">{{benchlearning.cusRelnumCustomerServed}}</span>
        <span *ngIf="!benchlearning.cusRelnumCustomerServed">--</span>
        customers, and around <span *ngIf="benchlearning.cusRelnumGeneralUsersServed">{{benchlearning.cusRelnumGeneralUsersServed}}</span>
        <span *ngIf="!benchlearning.cusRelnumGeneralUsersServed">--</span> general users
    </tr>

    <h4>Channels<img src="assets/channel.png" style="width:100%;height:auto;max-width:20px;margin-left: 5px"></h4>
    <tr>
      <th colspan="2">Premises Location:</th>
      <td colspan="4">{{getPremiseLocation(benchlearning.premiseLocation)}}</td>
    </tr>
    <tr style="margin-top: 10px" *ngIf="channelData">
      <th colspan="6">Current Channels</th>
    </tr>
    <tr *ngIf="channelData">
      <td colspan="6">
        <ng-container *ngFor="let curGroup of benchlearning.serviceChannelsGroups;let i = index">
          <div   *ngIf="benchlearning.serviceChannelsGroups[i].dataItems && benchlearning.serviceChannelsGroups[i].dataItems.length > 0">
            <h6>
              {{groupHeadersChannel[i]}}
            </h6>
            <table class="table">
              <tr>
                <th colspan="2" width="20%">Channel used</th>
                <th colspan="2" width="40%">Which of the channels works the best and why?</th>
                <th colspan="2" width="40%">Where is the room for improvement concerning reaching the customers?</th>
              </tr>
              <tr *ngFor="let curItem of benchlearning.serviceChannelsGroups[i].dataItems">
                <td colspan="2" width="20%">{{curItem.channel}}</td>
                <td colspan="2" width="40%">{{curItem.bestChannel}}</td>
                <td colspan="2" width="40%">{{curItem.roomImprovement}}</td>
              </tr>
            </table>
          </div>
        </ng-container>
      </td>
    </tr>
  </table>
</div>


import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Benchlearning} from "../benchmarking";
import {DataService} from "../../data-service";
import {Compare} from "./compare";
import {SimilarityResult} from "./SimilarityResult";
import {MatDialog, MatDialogConfig, Sort} from "@angular/material";
import {Popup_infoComponent} from "./popup_info.component";
import {DomSanitizer} from "@angular/platform-browser";
import {Chart} from "angular-highcharts";

@Component({
  templateUrl: './compare_overview.html',
  styleUrls: ['../benchmarking.component.css'],
})
export class CompareOverviewComponent implements OnInit {
  //compares one RO with a specific other or all other ROs
  compare: Compare = new Compare();
  similarityRes: SimilarityResult[] = [];
  similaritySingleRes: SimilarityResult = null;
  tableVisible = false;
  breadcrumVisible1 = true;
  breadcrumVisible2 = false;
  breadcrumb1;
  breadcrumb2;

  chart = new Chart( {chart: {
    type: 'column'
  },
    xAxis: {
      title: {
        text: 'RO Names'
      },
      labels:
        {
          enabled: false
        },
      categories: ['']
    },
    yAxis: {
      title: {
        text: 'Pearson Results'
      }
    },
    credits: {
      enabled: true



    }
  });


  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private sanitizer:DomSanitizer) {

  }

  ngOnInit(): void {
    var allName = new Benchlearning();
    allName.name = 'all';
    this.compare.curRo = JSON.parse(localStorage.getItem("compare"));
    this.dataService.getBenchlearning().subscribe(res => {
      this.compare.ros[0] = allName;
      this.breadcrumb1 = this.sanitizer.bypassSecurityTrustHtml('<a href="javascript:void(0)">Benchlearning</a> / ');

      for( var i of res.filter(obj => obj.id !== this.compare.curRo.id)){
        this.compare.ros.push(i);
      }
      this.onCompareAll();
    })
  }

  getToStartPage(){
    this.router.navigateByUrl('/benchlearning');
  }

  prepareCompare(){

    if(this.compare.compareRo.name === 'all'){
        this.onCompareAll();
    }else {
      this.breadcrumVisible2 = true;
      this.breadcrumb2 = this.sanitizer.bypassSecurityTrustHtml(' / <a href="javascript:void(0)">all</a> / ');
      this.onCompare();
    }
  }

  initializeChart(titleText){
    this.chart = new Chart( {chart: {
      type: 'column',
    },title: {
      useHTML: true,
      text: titleText
    },
      xAxis: {
        title: {
          text: "RO Names"
        },
        labels:
          {
            enabled: false
          },
        categories: ['']
      },
      yAxis: {
        title: {
          text: 'Pearson Results'
        }
      },
      credits: {
        enabled: true
      },series: []
    });

  }

  onCompare(){
    this.initializeChart("Similarity between <b>" +this.compare.curRo.name+ "</b> and <b>"+ this.compare.compareRo.name+"</b>");

    this.tableVisible = true;
    this.dataService.getDataCompareTwo(this.compare.curRo.id,this.compare.compareRo.id).subscribe(res =>{
      this.chart.removeSeries(0);
      this.similarityRes = [];
      this.similaritySingleRes = res;
      this.similarityRes.push(this.similaritySingleRes);

      for(let e of this.similarityRes){
          this.chart.addSeries({type: 'column', name: "Total", data: [e.results[7]]}, true, false);
        this.chart.addSeries({type: 'column', name: "Basic Info", data: [e.results[0]]}, true, false);
        this.chart.addSeries({type: 'column', name: "Customer Segment", data: [e.results[1]]}, true,false);
        this.chart.addSeries({type: 'column', name: "Value Proposition", data: [e.results[2]]}, true, false);
        this.chart.addSeries({type: 'column', name: "Key Resources", data: [e.results[3]]}, true,false);
        this.chart.addSeries({type: 'column', name: "Key Partners", data: [e.results[4]]}, true, false);
        this.chart.addSeries({type: 'column', name: "Customer Relationships", data: [e.results[5]]}, true, false);
        this.chart.addSeries({type: 'column', name: "Channels", data: [e.results[6]]}, true, false);
      }
    });

  }

  onCompareAll(){
    this.breadcrumVisible2 = false;
    this.compare.compareRo.name = 'all'
    this.initializeChart("Similarity between <b>"+ this.compare.curRo.name+"</b> and all other ROs");
    this.tableVisible = true;
    this.dataService.getDataCompareAll(this.compare.curRo.id).subscribe(res =>{
      this.similarityRes = res;
     this.similarityRes =  this.similarityRes.sort(function (a,b) {
        if(a.obsName < b.obsName){return -1;}
        if(a.obsName > b.obsName){return 1;}
        return 0;
      })

      for(let e of this.similarityRes){
        if(e.obsName !== '') {
          let tmp = e.obsName;
          this.chart.addSeries({
            type: 'column',
            name: e.obsName, data: [e.results[7]], events: {
              click:  (e) =>{
                this.compare.compareRo =  this.compare.ros.filter(x => {
                  if(x.name == tmp){ return x;}
                })[0];
                this.onCompare();
              }
            }
          }, true, false);
        }
      }
    })
  }


  openDialogInfo() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    this.dialog.open(Popup_infoComponent, dialogConfig);

  }


  sortData(sort: Sort) {
    this.similarityRes = this.similarityRes.sort((a, b) => {
      let isAsc = sort.direction == 'asc';
      switch (sort.active) {
        case 'Name':
          return compare(a.obsName, b.obsName, isAsc);
        case 'Pearson':
          return compare(a.results[7], b.results[7], isAsc);
        case 'Euclidean':
          return compare(a.results[0], b.results[0], isAsc);
        case 'Jaccard':
          return compare(a.results[1], b.results[1], isAsc);
        case 'Hamann':
          return compare(a.results[2], b.results[2], isAsc);
        case '2':
          return compare(a.results[3], b.results[3], isAsc);
        case '3':
          return compare(a.results[4], b.results[4], isAsc);
        case '4':
          return compare(a.results[5], b.results[5], isAsc);
        case '5':
          return compare(a.results[6], b.results[6], isAsc);
        default:
          return 0;
      }
    });
  }


}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

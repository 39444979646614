import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {MaterialModule} from '../material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RouterModule} from "@angular/router";
import {MatDialogModule, MatExpansionModule, MatTooltipModule} from "@angular/material";
import {DataService} from "../data-service";
import {ChartModule} from "angular-highcharts";
import {Popup_infoComponent} from "./popup_info.component";
import {RegionSectorComponent} from "./region_sector/region_sectors.component";
import {RegionalEconomyComponent} from "./regional_economy/regional_economy.component";
import {InnovationComponent} from "./innovation/innovation.component";
import {CompetitivenessComponent} from "./competitiveness/competitiveness.component";



@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpModule,
    FormsModule,
    MatTooltipModule,
    MatExpansionModule,
    ChartModule,
    MatDialogModule
  ],
  exports: [
    RegionSectorComponent,
    RegionalEconomyComponent,
    InnovationComponent,
    CompetitivenessComponent
  ],
  declarations: [
    Popup_infoComponent,
    RegionSectorComponent,
    RegionalEconomyComponent,
    InnovationComponent,
    CompetitivenessComponent
  ],
  entryComponents: [
    Popup_infoComponent
  ],
  providers: [
    DataService,
  ]
})
export class FrameworkModule {}

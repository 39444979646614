import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Headers, RequestOptions, ResponseContentType} from '@angular/http';

import {AuthService} from "./auth/auth.service";
import {NetworkStatusService} from "./network-status/network-status-service";
import {environment} from "../environments/environment";

export abstract class HTTPServiceBase {
  protected readonly client: HttpClient;
  protected readonly authService: AuthService;
  protected readonly networkStatus: NetworkStatusService;
  constructor(client: HttpClient, authService: AuthService, networkStatus: NetworkStatusService){
    this.client = client;
    this.authService = authService;
    this.networkStatus = networkStatus;
  }

  protected get<T>(url: string): Observable<T> {
    this.networkStatus.startRequest();
    const obs : any = this.client.get(environment.apiUrl + url, {
      headers: this.createHeaders()
    });
    return this.registerNetworkStatus(obs);
  }


  protected getPdf<T>(url: string): Observable<Blob> {
    this.networkStatus.startRequest();
    const obs = this.client.get(environment.apiUrl + url, {
      headers: this.createHeadersPdf(),
      responseType: 'blob'
    });
    return this.registerNetworkStatus(obs);
  }

  protected post<T>(url: string, payload: any): Observable<T> {
    this.networkStatus.startRequest();
    const obs : any= this.client.post(environment.apiUrl + url, payload, {
      headers: this.createHeaders()
    });
    return this.registerNetworkStatus(obs);

  }
  protected put<T>(url: string, payload: any): Observable<T> {
    this.networkStatus.startRequest();

    const obs :any = this.client.put(environment.apiUrl + url, payload, {
      headers: this.createHeaders()
    });
    return this.registerNetworkStatus(obs);

  }
  protected delete<T>(url: string): Observable<T> {
    this.networkStatus.startRequest();
    const obs: any = this.client.delete(environment.apiUrl + url, {
      headers: this.createHeaders()
    });
    return this.registerNetworkStatus(obs);
  }

  //Use to return a static value as an observable
  protected returnValue<T>(value: T): Observable<T>{
    return Observable.create(observer => {
      setTimeout(() => {
        observer.next(value);
        observer.complete();
      }, 2000);
    });
  }

  private registerNetworkStatus<T>(observable: Observable<T>): Observable<T> {
    const shareable = observable.share();
    shareable.subscribe( {
      complete: () => {setTimeout( () => this.networkStatus.stopRequest(), 400); },
      error: () => {this.networkStatus.stopRequest(); }
    });
    return shareable;
  }

  // Generates Headers
  private createHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type');

    const token = this.authService.tokenInfo;

    if (token != null) {
      return headers.set('Authorization', token.rawValue);
    }
    return headers;
  }

  // Generates Headers
  private createHeadersPdf(): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/pdf')
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/pdf')
      .set('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type, Accept');

    const token = this.authService.tokenInfo;

    if (token != null) {
      return headers.set('Authorization', token.rawValue);
    }
    return headers;
  }

}

export class RegionalEconomy{
  country: string = '';
  region : string = '';
  shortname : string = '';
  data: RegionalEconomyData[] = [];
}

export class RegionalEconomyData{
  year2005: number = 0;
  year2008 : number = 0;
  year2011 : number = 0;
  year2014 : number = 0;
  year2017 : number = 0;
}

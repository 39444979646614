<header _ngcontent-c9="" class="docs-header-background">
  <div _ngcontent-c9="" class="docs-header-section">
    <div _ngcontent-c9="" class="docs-header-headline">
      <h1 _ngcontent-c9="" class="mat-h1">

        <a routerlink="/" href="/" mat-button style='color: rgba(255, 255, 255, 0.87)'>

          <span><i class="material-icons" matTooltip="Benchlearning">arrow_back</i> </span>
        </a>
        D.T1.6.1 - Benchlearning </h1>
    </div>
  </div>
</header>


  <mat-expansion-panel [expanded]="showFilterSt">
    <mat-expansion-panel-header (click)="openCloseFilter()">
      <mat-panel-title>
       <mat-icon>filter_list</mat-icon><span> filter by Observatory Type ({{getPolicyTechnology()}}),
        Institution Type ({{getType()}}), Areas of Smart Specialisation ({{getAreas()}}) ({{amountRes}})</span>
      </mat-panel-title>

    </mat-expansion-panel-header>

    <mat-card-content>

      <h6 width="280px">Observatory Type</h6>
      <mat-checkbox class="input-width" [(ngModel)]="filter.obsPolicy" (change)="filterList()" value="policy" style="margin-left: 250px; width: 70px; margin-right: 20px">
        Policy
      </mat-checkbox>
      <mat-checkbox class="input-width" [(ngModel)]="filter.obsTechnology" style="width: 70px"
                    (change)="filterList()"
                    value="technology">Technology
      </mat-checkbox><br>

      <h6 width="250px">Institution Type</h6>
      <mat-radio-group [(ngModel)]="filter.type" id="type" style="margin-left: 250px">
        <mat-radio-button value="private" style="width: 70px" (change)="filterList()">private
        </mat-radio-button>
        <mat-radio-button  value="public" style="width: 70px" (change)="filterList()">public
        </mat-radio-button>
        <mat-radio-button  value="any" style="width: 70px" (change)="filterList()">any
        </mat-radio-button>
      </mat-radio-group><br>

      <h6 width="250px">Area(s) of smart specialisation</h6>
      <sw-multiple-choice-checkbox
        [options]="optionsArea"
        [(selected)]="filter.area"
        [enableBreak]="enableBreak"
        placeholder="Area(s) of smart specialisation"
        (selectedChange)="filterList()"
      >
      </sw-multiple-choice-checkbox>
      <br>

    </mat-card-content>
  </mat-expansion-panel>


<table class="table" *ngIf="authService.isPartnerOrLeadPartnerOrAdminOrBenchlearningOrAuditPlusOrJs()">
  <tr>
    <th>Name</th>
    <th>County</th>
    <th>Region</th>
    <th>Action</th>
  </tr>
  <tr *ngFor="let element of sortedList">
    <td>{{element.name}}</td>
    <td>{{element.country}}</td>
    <td>{{element.region}}</td>
    <td>
      <button  mat-mini-fab color="primary" matTooltip="Show details" (click)="onShowDetail(element)" style="margin:5px;"><mat-icon aria-label="show details">details</mat-icon></button>
      <button mat-mini-fab color="primary"  matTooltip="Compare ROs" (click)="compareROs(element)" style="margin:5px;"><mat-icon aria-label="Compare ROs">compare_arrows</mat-icon></button>
      <button mat-mini-fab color="primary" matTooltip="D.T1.3.1 - Generate Audit-report of RO" (click)="onPrint(element)" style="margin:5px;"><mat-icon aria-label="Generate Audit-report of RO">print</mat-icon></button>

    </td>
  </tr>
</table>
<h3 *ngIf="!authService.isPartnerOrLeadPartnerOrAdminOrBenchlearningOrAuditPlusOrJs()">Only users with the roles Benchlearning, Partner, Lead-Partner, AuditPlus or Admin can see the benchlearning data!</h3>
